import React, {useState} from "react";

export const Dropdown = ({items, selected = [], onChange}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);


    const handleItemClick = (item) => {
        if (!onChange) {
            return;
        }
        const isSelected = selected.some(selectedItem => selectedItem.id === item.id);
        const newSelected = isSelected
            ? selected.filter(selectedItem => selectedItem.id !== item.id)
            : [...selected, item];

        onChange(newSelected);
    };

    return <div className="relative inline-block text-left">
        <button
            onClick={toggleDropdown}
            className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
            {selected.length ? `${selected.length === 1 ? selected[0].title : selected.length + ' выбрано'}` : 'Выбрать'}
            <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                 fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"/>
            </svg>
        </button>

        {isOpen && (
            <div
                className="origin-top-right z-[999] absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" style={{background: 'white'}}>
                <div className="py-1">
                    {items.map((item, index) =>
                        <button
                            key={index}
                            onClick={() => {
                                item.click(item)
                                handleItemClick(item)
                                setIsOpen(!isOpen)
                            }}
                            className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left ${selected.some(selectedItem => selectedItem.id === item.id) ? 'bg-gray-200' : ''}`}
                        >
                            {item.title}
                        </button>
                    )}
                </div>
            </div>
        )}
    </div>
}
