export const Size = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_6932)">
            <path
                d="M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H6V15H2V14H6V13H4V12H6V11H2V10H6V9H4V8H6V7H2V6H6V2H7V6H8V4H9V6H10V2H11V6H12V4H13V6H14V2H15V6H16V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0L1 0Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_2_6932">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}
