import React, { useState, useEffect, useRef } from "react";
import { Map, View } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM } from 'ol/source';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Point } from 'ol/geom';
import { Feature } from 'ol';
import { Icon, Style } from 'ol/style';
import classes from "./Form.module.scss";
import InputMask from "react-input-mask";

export const Form = ({ item, onCancel, onSave, errors, setErrors }) => {
    const defaultTimes = {
        "0": "",
        "1": "",
        "2": "",
        "3": "",
        "4": "",
        "5": "",
        "6": ""
    };

    const [title, setTitle] = useState(item ? item.title : '');
    const [email, setEmail] = useState(item ? item.email : '');
    const [address, setAddress] = useState(item ? item.address : '');
    const [latitude, setLatitude] = useState(item ? item.latitude : '');
    const [longitude, setLongitude] = useState(item ? item.longitude : '');
    const [phones, setPhones] = useState(item ? item.phones : []);
    const [times, setTimes] = useState(item && item.times ? item.times : defaultTimes);

    const mapRef = useRef();

    useEffect(() => {
        const initialCoordinates = item && item.latitude && item.longitude ?
            fromLonLat([item.longitude, item.latitude]) :
            fromLonLat([37.6173, 55.7558]); // Москва как дефолтное значение

        const iconFeature = new Feature({
            geometry: new Point(initialCoordinates),
        });

        iconFeature.setStyle(new Style({
            image: new Icon({
                anchor: [0.5, 1],
                src: 'https://openlayers.org/en/latest/examples/data/icon.png', // Замени на собственный маркер, если нужно
            }),
        }));

        const vectorSource = new VectorSource({
            features: [iconFeature],
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });

        const map = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                vectorLayer,
            ],
            view: new View({
                center: initialCoordinates,
                zoom: 12,
            }),
        });

        map.on('click', function (evt) {
            const coordinates = toLonLat(evt.coordinate);
            setLongitude(coordinates[0]);
            setLatitude(coordinates[1]);

            iconFeature.getGeometry().setCoordinates(evt.coordinate);
            updateAddressFromCoordinates(coordinates);
        });

        return () => map.setTarget(null);
    }, [item]);

    const updateAddressFromCoordinates = async (coordinates) => {
        const [lon, lat] = coordinates;
        // Используем API геокодирования (например, Google Maps, Яндекс или другой сервис)
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`);
        const data = await response.json();
        const newAddress = data.display_name;
        setAddress(newAddress);
    };

    const updateCoordinatesFromAddress = async (newAddress) => {
        setAddress(newAddress);
        const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(newAddress)}`);
        const data = await response.json();

        if (data.length > 0) {
            const { lat, lon } = data[0];
            const newCoordinates = fromLonLat([parseFloat(lon), parseFloat(lat)]);
            setLatitude(lat);
            setLongitude(lon);

            // Обновляем положение маркера на карте
            const iconFeature = mapRef.current.getLayers().getArray()[1].getSource().getFeatures()[0];
            iconFeature.getGeometry().setCoordinates(newCoordinates);

            // Перемещаем центр карты к новому местоположению
            mapRef.current.getView().setCenter(newCoordinates);
        }
    };

    const handleAddressChange = (e) => {
        updateCoordinatesFromAddress(e.target.value);
    };

    const handleSubmit = () => {
        setErrors([])
        const category = {
            id: item?.id,
            title,
            email,
            address,
            latitude,
            longitude,
            phones,
            times
        };
        onSave(category);
    }

    const handlePhoneChange = (index, value) => {
        setErrors([])
        const updatedPhones = [...phones];
        updatedPhones[index] = value;
        setPhones(updatedPhones);
    };

    const addPhone = () => {
        setPhones([...phones, '']);
    };

    const removePhone = (index) => {
        const updatedPhones = phones.filter((_, i) => i !== index);
        setPhones(updatedPhones);
    };

    const handleTimeChange = (day, value) => {
        setErrors([])
        setTimes({ ...times, [day]: value });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'space-between' }}>
            <div className={classes.formActions}>
                <button style={{ marginTop: '10px' }} className={classes.cancel} onClick={onCancel}>Назад</button>
                <button className={classes.save} onClick={handleSubmit}>Сохранить</button>
            </div>
            <div className={classes.column}>
                <div className={classes.row}>
                    <div className={classes.cart}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Информация</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    <tr>
                                        <td>Название</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={title}
                                                onChange={(e) => {
                                                    setTitle(e.target.value)
                                                    setErrors([])
                                                }}
                                                placeholder="Название"
                                                className={errors.find(err => err.type === 'title') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'title') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'title').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Адрес</td>
                                        <td style={{position: 'relative'}}>
                                            <input
                                                type="text"
                                                value={address}
                                                onChange={handleAddressChange}
                                                placeholder="Адрес"
                                                className={errors.find(err => err.type === 'address') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'address') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'address').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Почта</td>
                                        <td>
                                            <input
                                                type="email"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                    setErrors([])
                                                }}
                                                placeholder="Почта"
                                                className={errors.find(err => err.type === 'email') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'email') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'email').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Телефоны</td>
                                        <td>
                                            {phones.map((phone, index) => (
                                                <div key={index} style={{
                                                    display: "flex",
                                                    alignItems: 'center',
                                                    marginBottom: '10px',
                                                    gap: '10px'
                                                }}>
                                                    <InputMask
                                                        type="text"
                                                        value={phone}
                                                        mask="+9(999)999-99-99"
                                                        onChange={(e) => handlePhoneChange(index, e.target.value)}
                                                        placeholder="Телефон"
                                                        className={errors.find(err => err.type === 'phone') ? 'error' : ''}
                                                    />

                                                    <button className={classes.del}
                                                            onClick={() => {
                                                                removePhone(index)
                                                                setErrors([])
                                                            }}>Удалить
                                                    </button>
                                                </div>
                                            ))}
                                            <button className={classes.save} onClick={() => {
                                                addPhone()
                                                setErrors([])
                                            }} style={{width: '200px'}}>Добавить телефон
                                            </button>
                                            {errors.find(err => err.type === 'phone') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'phone').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cart}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Выбор местоположения</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div ref={mapRef} style={{width: '100%', height: '400px'}}/>
                        </div>
                    </div>
                </div>
                <div className={classes.cart}>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <h2>Часы работы</h2>
                        </div>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.responsive}>
                            <table className={classes.table}>
                                <tbody className={classes.tbody}>
                                {Object.keys(times).map(day => (
                                    <tr key={day}>
                                        <td>{["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"][day]}</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={times[day]}
                                                onChange={(e) => handleTimeChange(day, e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
