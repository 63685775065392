export const Material = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_6678)">
            <path
                d="M14.12 10.1629L15.835 11.0209C16.055 11.1309 16.055 11.4449 15.835 11.5549L8.26705 15.3399C8.18413 15.3813 8.09272 15.4028 8.00005 15.4028C7.90738 15.4028 7.81597 15.3813 7.73305 15.3399L0.16505 11.5549C0.115611 11.53 0.0740622 11.4918 0.045032 11.4447C0.0160018 11.3976 0.000630377 11.3433 0.000630377 11.2879C0.000630377 11.2326 0.0160018 11.1783 0.045032 11.1311C0.0740622 11.084 0.115611 11.0458 0.16505 11.0209L1.88105 10.1629L7.19805 12.8219C7.70305 13.0739 8.29805 13.0739 8.80205 12.8219L14.1191 10.1619L14.12 10.1629ZM7.73305 0.0629159C7.81597 0.0215386 7.90738 0 8.00005 0C8.09272 0 8.18413 0.0215386 8.26705 0.0629159L15.835 3.84692C15.8844 3.872 15.9259 3.91026 15.9549 3.95748C15.9839 4.0047 15.9992 4.05902 15.9992 4.11442C15.9992 4.16982 15.9839 4.22414 15.9549 4.27135C15.9259 4.31857 15.8844 4.35684 15.835 4.38192L8.26705 8.16492C8.18413 8.20629 8.09272 8.22783 8.00005 8.22783C7.90738 8.22783 7.81597 8.20629 7.73305 8.16492L0.16505 4.38192C0.115349 4.3571 0.0735459 4.31892 0.0443282 4.27167C0.0151105 4.22442 -0.000366211 4.16997 -0.000366211 4.11442C-0.000366211 4.05886 0.0151105 4.00441 0.0443282 3.95716C0.0735459 3.90991 0.115349 3.87174 0.16505 3.84692L7.73305 0.0629159Z"
                fill="white"/>
            <path
                d="M14.12 6.57592L15.835 7.43392C16.055 7.54392 16.055 7.85792 15.835 7.96792L8.26705 11.7519C8.18413 11.7933 8.09272 11.8148 8.00005 11.8148C7.90738 11.8148 7.81597 11.7933 7.73305 11.7519L0.16505 7.96792C0.115611 7.943 0.0740622 7.90484 0.045032 7.8577C0.0160018 7.81056 0.000630377 7.75628 0.000630377 7.70092C0.000630377 7.64555 0.0160018 7.59128 0.045032 7.54413C0.0740622 7.49699 0.115611 7.45884 0.16505 7.43392L1.88105 6.57592L7.19805 9.23492C7.70305 9.48692 8.29805 9.48692 8.80205 9.23492L14.12 6.57592Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_2_6678">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}
