import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './NewsPage.module.scss'
import {JournalService} from "../../service/journalService";
import {Form} from "./Form";
import {List} from "../../сomponents/list/List";
import DeleteConfirmation from "../../сomponents/confirmDelete/DeleteConfirmation";

export const JournalPage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    const journalService = useMemo(() => new JournalService(), [])

    const getAll = useCallback(async () => {
        setLoading(true)
        const res = await journalService.getAll()
        console.log(res)
        if (res.success)
            setItems(res.data)
        setLoading(false)
    }, [journalService])

    useEffect(() => {
        getAll().then().catch()
    }, [getAll]);

    const handleAddClick = () => {
        setCreate(true);
        setEditItem(null);
    }

    const handleEditClick = (item) => {
        setEditItem(item);
        setCreate(true);
    }

    const handleCancel = () => {
        setCreate(false);
        setEditItem(null);
    }

    const handleSave = async (item) => {
        if(item.id === undefined) {
            await journalService.create(item.title)
        } else {
            await journalService.edit(item.id, item.title)
        }
        setCreate(false);
        setEditItem(null);
        getAll()
    }

    const handleDeleteClick = (item) => {
        setDeleteItem(item);
    }

    const handleConfirmDelete = async () => {
        if (deleteItem) {
            try {
                await journalService.delete(deleteItem.id);
                setDeleteItem(null);
                getAll();
            } catch (err) {
                alert(err)
            }
        }
    }

    const handleCancelDelete = () => {
        setDeleteItem(null);
    }

    const columns = [
        {header: 'Название', accessor: "title"}
    ]

    return <div className={classes.content}>
        <h2 className={classes.title}>Журнал</h2>

        {!create && !deleteItem ? (
            <>
                <button className={classes.addBtn} onClick={handleAddClick}>Добавить</button>
                <List columns={columns} loading={loading} items={items} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />
            </>
        ) : create ? (
            <Form
                item={editItem}
                onCancel={handleCancel}
                onSave={handleSave}
            />
        ) : deleteItem && (
            <DeleteConfirmation
                title={`Вы уверены, что хотите удалить ${deleteItem.title}?`}
                item={deleteItem}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
    </div>
}
