import {instance} from "./https";

export class CertTypeService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getAll = async () => {
        const response = await instance.get('/certificate_type', {
            headers: {
                Authorization: this.token,
                accept: 'application/json'
            }
        });
        return response.data;
    }

    create = async (value, description) => {
        try {
            const response = await instance.post("/certificate_type", {
                value, description,
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    edit = async (id, value, description) => {
        try {
            const response = await instance.put(`/certificate_type/${id}`, {
                value, description,
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/certificate_type/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
}
