import React from 'react';
import classes from './StatusIndicator.module.scss';

const StatusIndicator = ({ status }) => {
    return (
        <div className={`${classes.statusIndicator} ${status ? classes.active : classes.inactive}`}>
            {status ? 'Активен' : 'Неактивен'}
        </div>
    );
};

export default StatusIndicator;
