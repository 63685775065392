import React, {useState} from "react";
import classes from "./Form.module.scss";

export const Form = ({item, onCancel, onSave}) => {
    const [value, setValue] = useState(item ? item.value : '');
    const [description, setDescription] = useState(item ? item.description : '');


    const handleSubmit = () => {
        const category = { id: item?.id, value, description };
        onSave(category); // вызываем функцию сохранения
    }


    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
            <div className={classes.formActions}>
                <button style={{marginTop: '10px'}} className={classes.cancel} onClick={onCancel}>Назад</button>
            </div>
            <div className={classes.row}>
                <div className={classes.cart}>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <h2>Информация</h2>
                        </div>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.responsive}>
                            <table className={classes.table}>
                                <tbody className={classes.tbody}>
                                <tr>
                                    <td>Стоимость</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            placeholder="Стоимость"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Описание</td>
                                    <td>
                                        <textarea
                                            type="text"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder="Описание"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <button className={classes.save} onClick={handleSubmit}>Сохранить</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
