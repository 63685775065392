import React, {useEffect, useState, useMemo} from "react";
import classes from "./Form.module.scss";
import {List} from "../../сomponents/list/List";
import {getStatus} from "../../сomponents/support/getStatus";
import {OrderService} from "../../service/orderService";
import {ReviewsService} from "../../service/reviewsService";
import Review from "../../assets/Review";
import {BonusService} from "../../service/bonusService";
import {CartService} from "../../service/cartService";


export const Form = ({item, onCancel, onSave}) => {
    const [orders, setOrders] = useState([])
    const [reviews, setReviews] = useState([])
    const [loading, setLoading] = useState(true)
    const [bonuses, setBonuses] = useState(true)
    // const [cart, setCart] = useState(null)
    const [edit, setEdit] = useState(false);

    const [firstName, setFirstName] = useState(item ? item.first_name : '');
    const [lastName, setLastName] = useState(item ? item.last_name : '');
    const [phone, setPhone] = useState(item ? item.phone : '');
    const [email, setEmail] = useState(item ? item.email : '');
    const [role, setRole] = useState(item ? item.role : undefined);

    const bonusService = useMemo (() => new BonusService(), [])
    const reviewsService = useMemo(() => new ReviewsService(), [])
    const orderService = useMemo(() => new OrderService(), [])
    const cartService = useMemo(() => new CartService(), [])

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true)
                const [dataOrder, dataReview, dataBonuses, dataCart] = await Promise.all([
                    orderService.getAll(item.id, undefined, undefined, undefined, 10, 0, undefined),
                    reviewsService.getAll(item.id),
                    bonusService.getByUserId(item.id),
                    // cartService.getUser(item.id),
                ]);
                console.log(dataCart)

                if (dataOrder.success) setOrders(dataOrder.data)
                if (dataReview.success) setReviews(dataReview.data)
                if (dataBonuses.success) setBonuses(dataBonuses.data)
                // if (dataCart.success) setCart(dataCart.data)

            } catch (error) {
                console.error("Ошибка загрузки данных:", error);
            } finally {
                setLoading(false); // завершение загрузки
            }
        }
        fetchData();
    }, [bonusService, cartService, orderService, reviewsService, item])


    const handleSubmit = () => {
        const category = {id: item?.id, first_name: firstName, last_name: lastName, phone: phone, email: email, role: role};
        onSave(category); // вызываем функцию сохранения
    }

    const roles = {
        admin: 'Админ',
        moderator: 'Модератор',
        user: 'Пользователь'
    }

    const rolesStatus = [
        { title: 'Админ', id: 'admin', click: () => setRole('admin') },
        { title: 'Модератор', id: 'moderator', click: () => setRole('moderate') },
        { title: 'Пользователь', id: 'user', click: () => setRole('user') },
    ];

    const bonusesColumn = [
        {header: 'Дата', accessor: 'created_at', render: (item) => {

                return <p style={{fontWeight: 'bold'}}>{new Date(item.created_at).toLocaleDateString() + " " + new Date(item.created_at).toLocaleTimeString()}</p>
            }
        },
        {header: 'Описание', accessor: 'description' },
        {header: 'Количество', accessor: 'count'},
        {header: 'Тип', accessor: 'type', render: (item) => {
                return item.type === 'minus' ? 'Списание' : 'Зачисление'
            }}
    ]

    const ordersColumns = [
        {header: 'Номер заказа', accessor: 'id', render: (item) => {
                const idArray = item.id.split('-')
                return <p style={{fontWeight: 'bold'}}>{idArray[idArray.length - 1]}</p>
            }
        },
        {header: 'Статус', accessor: 'status', render: (item) => {
                return getStatus(item.status)
            }},
        {header: 'Сумма', accessor: 'total_amount', render: (item) => {
                return item.total_amount + "₽"
            }},
        {header: 'Дата оформления', accessor: 'created_at', render: (item) => {
                return new Date(item.created_at).toLocaleDateString() + " " + new Date(item.created_at).toLocaleTimeString()
            }}
    ]


    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
            <div className={classes.formActions}>
                <button style={{marginTop: '10px'}} className={classes.cancel} onClick={onCancel}>Назад</button>
            </div>
            <div className={classes.column}>
                <div className={classes.row}>
                    <div className={classes.cart} style={{maxWidth: '400px'}}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Информация</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    <tr>
                                        <td>
                                            {edit && <button className={classes.cancel} onClick={() => {
                                                setEdit(false)
                                            }}>Отмена</button>}
                                        </td>
                                        <td>
                                            <button className={`${classes.save} float-right`} onClick={() => {
                                                setEdit(true);
                                                if (edit)
                                                    handleSubmit()
                                            }}>{!edit ? 'Редактировать' : 'Сохранить'}
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Имя</td>
                                        <td>{!edit ? item.first_name : <input
                                            type="text"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            placeholder="Имя"
                                        />}</td>
                                    </tr>
                                    <tr>
                                        <td>Фамилия</td>
                                        <td>{!edit ? item.last_name : <input
                                            type="text"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            placeholder="Имя"
                                        />}</td>
                                    </tr>
                                    <tr>
                                        <td>Телефон</td>
                                        <td>{!edit ? item.phone : <input
                                            type="text"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            placeholder="Имя"
                                        />}</td>
                                    </tr>
                                    <tr>
                                        <td>Почта</td>
                                        <td>{!edit ? item.email : <input
                                            type="text"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Имя"
                                        />}</td>
                                    </tr>
                                    <tr>
                                        <td>Роль</td>
                                        <td>
                                            {!edit
                                                ? roles[item.role]
                                                : <select
                                                    value={role}
                                                    onChange={(e) => setRole(e.target.value)}
                                                >
                                                    {rolesStatus.map((roleOption) => (
                                                        <option key={roleOption.id} value={roleOption.id}>
                                                            {roleOption.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Дата регистрации</td>
                                        <td>{new Date(item.created_at).toLocaleDateString() + " " + new Date(item.created_at).toLocaleTimeString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Дата обновления</td>
                                        <td>{new Date(item.updated_at).toLocaleDateString() + " " + new Date(item.updated_at).toLocaleTimeString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Количество бонусов</td>
                                        <td>{item.bonus}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cart}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Список отзывов</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    {!loading && reviews.map((item) => {
                                        return <Review review={item}/>
                                    })}
                                    {loading && <div className={classes.loader}/>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.cart}>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <h2>Список заказов</h2>
                        </div>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.responsive}>
                            <table className={classes.table}>
                                <tbody className={classes.tbody}>
                                <List
                                    columns={ordersColumns}
                                    loading={loading}
                                    handleDeleteClick={() => {
                                    }}
                                    handleEditClick={() => {
                                    }}
                                    hiddenAction={true}
                                    items={orders}/>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={classes.cart}>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <h2>Действия с бонусами</h2>
                        </div>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.responsive}>
                            <table className={classes.table}>
                                <tbody className={classes.tbody}>
                                <List
                                    columns={bonusesColumn}
                                    loading={loading}
                                    handleDeleteClick={() => {
                                    }}
                                    handleEditClick={() => {
                                    }}
                                    hiddenAction={true}
                                    items={bonuses}/>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/*<div className={classes.cart} style={{marginTop: '2.5rem'}}>*/}
                {/*    <div className={classes.header}>*/}
                {/*        <div className={classes.title}>*/}
                {/*            <h2>Корзина пользователя</h2>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className={classes.body}>*/}
                {/*        <div className={classes.responsive}>*/}
                {/*            <CartList cart={cart} loading={loading} />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}


export const CartItem = ({ item, onRemove }) => {
    console.log(item)
    return (
        <div className="flex items-center justify-between border-b py-4">
            <div className="flex items-center space-x-4">
                <img
                    src={"https://api.uzorutum.ru/public" + item.product.images[0].path}
                    alt={item.product.title}
                    className="w-24 h-24 object-cover"
                />
                <div>
                    <h4 className="font-semibold text-lg">{item.product.title}</h4>
                    <p className="text-gray-600">{item.product.price}₽</p>
                </div>
            </div>
            <button
                onClick={() => onRemove(item.id)}
                className="text-red-500 hover:text-red-700"
            >
                Удалить
            </button>
        </div>
    );
};

export const CartList = ({ cart, loading }) => {
    const [items, setItems] = useState(cart ? cart.items : []);

    const handleRemove = (id) => {
        setItems(items.filter((item) => item.id !== id));
    };

    if(loading || !cart) return <div className={classes.loader}></div>

    return (
        <div className="p-6 bg-white rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Корзина пользователя</h2>
            </div>

            <div className="divide-y divide-gray-200">
                {items.map((item) => (
                        <>
                            {console.log(item)}
                            <CartItem key={item.id} item={item} onRemove={handleRemove} />
                        </>
                    ))}
            </div>

        </div>
    );
};

