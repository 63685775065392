import {instance} from "./https";

export class BonusService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getAll = async (limit, offset, user_id) => {
        const limitParam = limit ? `limit=${limit}` : '';
        const offsetParam = offset ? `offset=${offset}` : '';
        const queryParams = [limitParam, offsetParam]
            .filter(param => param)
            .join('&');

        const url = `/bonuses?${queryParams}`;

        const response = await instance.get(url, {
            headers: {
                Authorization: this.token,
                'accept': 'application/json'
            }
        });
        return response.data;
    }

    getByUserId = async (user_id, limit, offset) => {

        const limitParam = limit ? `limit=${limit}` : '';
        const offsetParam = offset ? `offset=${offset}` : '';
        const queryParams = [limitParam, offsetParam]
            .filter(param => param)
            .join('&');

        const url = `/bonuses/user/${user_id}?${queryParams}`;

        const response = await instance.get(url, {
            headers: {
                Authorization: this.token,
                accept: 'application/json'
            }
        });
        return response.data;
    }

    create = async (title, description, price) => {
        try {
            const response = await instance.post("/bonuses", {
                title, description, price
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    edit = async (id, title, description, price) => {
        try {
            const response = await instance.put(`/bonuses/${id}`, {
                title, description, price
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/bonuses/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }
}
