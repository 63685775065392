import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './ProductPage.module.scss'
import {ProductService} from "../../service/productService";
import {Form} from "./Form";
import {List} from "../../сomponents/list/List";
import DeleteConfirmation from "../../сomponents/confirmDelete/DeleteConfirmation";
import {CategoryService} from "../../service/categoryService";
import {MaterialService} from "../../service/materialService";
import {SizeService} from "../../service/sizeService";
import {DecorService} from "../../service/decorService";
import {ProbaService} from "../../service/probaService";
import {DiscountService} from "../../service/discountService";

export const ProductPage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);


    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const [categories, setCategories] = useState([])
    const [decorates, setDecorates] = useState([])
    const [materials, setMaterials] = useState([])
    const [sizes, setSizes] = useState([])
    const [probs, setProbs] = useState([])

    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedMaterialId, setSelectedMaterialId] = useState(null);
    const [selectedProbIds, setSelectedProbIds] = useState([]);
    const [selectedDecorationIds, setSelectedDecorationIds] = useState([]);
    const [selectedSizeIds, setSelectedSizeIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [errors, setErrors] = useState([])

    const [activeDiscount, setActiveDiscount] = useState(false)
    const productService = useMemo(() => new ProductService(), []);
    const categoryService = useMemo(() => new CategoryService(), []);
    const materialService = useMemo(() => new MaterialService(), []);
    const sizeService = useMemo(() => new SizeService(), []);
    const decorService = useMemo(() => new DecorService(), []);
    const probaService = useMemo(() => new ProbaService(), []);

    const getAll = useCallback(async () => {
        setLoading(true)
        setItems([])
        try {
            const [productRes, categoriesRes, materialsRes, sizesRes, decorRes, probRes] = await Promise.all([
                productService.getAll(search, limit, offset, selectedCategoryId, selectedMaterialId, selectedProbIds, selectedDecorationIds, selectedSizeIds),
                categoryService.getAll(),
                materialService.getAll(),
                sizeService.getAll(),
                decorService.getAll(),
                probaService.getAll()
            ]);

            if (categoriesRes.success) {
                const resCat = categoriesRes.data.map(item => {
                    item.click = (item) => {
                        console.log(item)
                        setSelectedCategoryId(item.id)
                    }
                    return {
                        ...item,
                        name: item.title // Исправляем название поля
                    }
                })
                setCategories(resCat)
            }

            if (materialsRes.success) {
                const matCat = materialsRes.data.map(item => {
                    item.click = (item) => {
                        console.log(item)
                        setSelectedMaterialId(item.id)
                    }
                    return {
                        ...item,
                        name: item.title // Исправляем название поля
                    }
                })
                setMaterials(matCat)
            }

            if (sizesRes.success) {
                const sizeCat = sizesRes.data.map(item => {
                    item.click = (item) => {
                        console.log(item)
                        setSelectedSizeIds(item.id)
                    }
                    return {
                        ...item,
                        name: item.title // Исправляем название поля
                    }
                })
                setSizes(sizeCat);
            }

            if (decorRes.success) {
                const decorCat = decorRes.data.map(item => {
                    item.click = (item) => {
                        console.log(item)
                        setSelectedDecorationIds(item.id)
                    }
                    return {
                        ...item,
                        name: item.title // Исправляем название поля
                    }
                })
                setDecorates(decorCat);
            }

            if (probRes.success) {
                const probCat = probRes.data.map(item => {
                    item.click = (item) => {
                        console.log(item)
                        setSelectedProbIds(item.id)
                    }
                    return {
                        ...item,
                        name: item.title // Исправляем название поля
                    }
                })
                setProbs(probCat)
            }
            if (productRes.success) {
                setItems(productRes.data)
                setTotalItems(productRes.pagination.totalItems);
            }

        } catch (error) {
            console.error("Ошибка загрузки данных:", error);
        } finally {
            setLoading(false); // завершение загрузки
        }

        setLoading(false)
    }, [
        productService, categoryService, materialService, sizeService, decorService, probaService,
        search, limit, offset, selectedCategoryId, selectedMaterialId, selectedDecorationIds, selectedSizeIds, selectedProbIds
    ]);

    const handleCategoryChange = (e) => {
        const selectedId = e.target.value;
        console.log(selectedId)
        setSelectedCategoryId(selectedId ? selectedId : null);
    };

    const handleMaterialChange = (e) => {
        const selectedId = e.target.value;
        setSelectedMaterialId(selectedId ? selectedId : null);
    };

    useEffect(() => {
        getAll()
    }, [getAll, search, limit, offset, selectedCategoryId, selectedMaterialId, selectedDecorationIds, selectedSizeIds, selectedProbIds, create, editItem]);

    const handleAddClick = () => {
        setCreate(true);
        setEditItem(null);
    }

    const handleEditClick = (item) => {
        setEditItem(item);
        console.log(item)
        setCreate(true);
    }

    const handleDecorChange = (e) => {
        const selectedIds = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedDecorationIds(selectedIds);
    };

    const handleSizeChange = (e) => {
        const selectedIds = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedSizeIds(selectedIds);
    };

    const handleProbsChange = (e) => {
        const selectedIds = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedProbIds(selectedIds);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleLimitChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
    };

    const handlePageChange = (page) => {
        setOffset(page - 1);
    };

    const handleCancel = () => {
        setCreate(false);
        setEditItem(null);
    }

    const handleSave = async (item) => {
        try {
            if(item.id === undefined) {
                await productService.save(item)
            } else {
                await productService.edit(item.id, item)
            }
            setTimeout(() => {
                setCreate(false);
                setEditItem(null);
            }, [1000])
        } catch (err) {
            const errors = JSON.parse(err.message)
            setErrors(errors);
            console.log('Errors: ', errors);
        }
    }

    const handleDeleteClick = (item) => {
        setDeleteItem(item);
    }

    const handleConfirmDelete = async () => {
        if (deleteItem) {
            await productService.delete(deleteItem.id);
            setDeleteItem(null);
            getAll();
        }
    }

    const handleOpenClick = () => {
        setActiveDiscount(!activeDiscount)
    }

    const handleCancelDelete = () => {
        setDeleteItem(null);
    }

    const columns = [
        {
            header: 'Превью',
            accessor: "images",
            render: item => {
                return <>
                    {item.images.length > 0
                        ? <img src={"https://api.uzorutum.ru/public" + item.images[0].path} width={50} alt={item.title}/>
                        : '-'
                    }
                </>
            }
        },
        {
            header: 'Название',
            accessor: "title",
            render: item => {
                return <>
                    <p style={{fontWeight: 'bold'}}>{item.title}</p>
                </>
            }
        },
        { header: 'Артикль', accessor: "article" },
        { header: 'Цена', accessor: "price", render: item => {
            return item.price + "₽"
            } },
        { header: 'Количество', accessor: "available" },
    ]

    const handleDiscountSubmit = async (data) => {
        try {
            for (const item of selectedItems) {
                await DiscountService.create({
                    productId: item,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    active: data.activated,
                    percentage: data.percentage
                });
            }
            setSelectedItems([]);
            setActiveDiscount(false)
            alert("Скидки успешно применены!");
        } catch (error) {
            console.error("Ошибка при создании скидки:", error);
            alert("Ошибка при создании скидки.");
        }
    };

    const handleMouseDown = (e) => {
        // Отмена стандартного поведения выбора
        e.preventDefault();
        const option = e.target;
        const optionValue = option.value;

        // Проверка, выбран ли уже этот элемент
        if (selectedDecorationIds.includes(optionValue)) {
            setSelectedDecorationIds(prevSelected =>
                prevSelected.filter(id => id !== optionValue)
            );
        } else {
            setSelectedDecorationIds(prevSelected => [...prevSelected, optionValue]);
        }
    };



    return <div className={classes.content}>
        <h2 className={classes.title}>Продукты</h2>
        {!create && !deleteItem
            ? <>
                <div className="flex flex-col md:flex-row justify-between mb-4">
                    <div className="flex space-x-2">
                        <button className={classes.save} onClick={handleAddClick} style={{marginBottom: '20px'}}>Добавить</button>

                        {selectedItems.length > 0
                            && <button className={classes.save} onClick={handleOpenClick}>Создать скидки</button>}
                    </div>
                    <div className="flex space-x-2">
                        <input
                            type="text"
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="Поиск..."
                            className="px-4 py-2 rounded border border-gray-300"
                        />
                        <select
                            value={limit}
                            onChange={handleLimitChange}
                            className="px-4 py-2 rounded border border-gray-300"
                        >
                            {[10, 20, 50].map(limitOption => (
                                <option key={limitOption} value={limitOption}>{limitOption} на странице</option>
                            ))}
                        </select>
                    </div>
                </div>
                {activeDiscount && (
                    <DiscountForm onSubmit={handleDiscountSubmit} onCancel={() => {setSelectedItems([]); setActiveDiscount(false)}} />
                )}
                <div className="grid flex-col space-y-4 md:space-y-0 md:flex-row justify-between mb-4">
                    <div className="flex flex-wrap gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Категория</label>
                            <select
                                value={selectedCategoryId || ''}
                                onChange={handleCategoryChange}
                                className="px-4 py-2 rounded border border-gray-300"
                            >
                                <option value="">Выберите категорию</option>
                                {categories.map(category => (
                                    <option key={category.id} value={category.id}>
                                        {category.name} {/* Исправляем для правильного отображения */}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Материал</label>
                            <select
                                value={selectedMaterialId || ''}
                                onChange={handleMaterialChange}
                                className="px-4 py-2 rounded border border-gray-300"
                            >
                                <option value="">Выберите материал</option>
                                {materials.map(material => (
                                    <option key={material.id} value={material.id}>
                                        {material.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Декор</label>
                            <select
                                multiple
                                value={selectedDecorationIds}
                                onChange={handleDecorChange}
                                className="px-4 py-2 rounded border border-gray-300"
                            >
                                {decorates.map(decor => (
                                    <option key={decor.id} value={decor.id} className={selectedDecorationIds.includes(decor.id) ? 'selected' : ''}>
                                        {decor.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Размер</label>
                            <select
                                multiple
                                value={selectedSizeIds}
                                onChange={handleSizeChange}
                                className="px-4 py-2 rounded border border-gray-300"
                            >
                                {sizes.map(size => (
                                    <option key={size.id} value={size.id} className={selectedSizeIds.includes(size.id) ? 'selected' : ''}>
                                        {size.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Проба</label>
                            <select
                                multiple
                                value={selectedProbIds}
                                onChange={handleProbsChange}
                                className="px-4 py-2 rounded border border-gray-300"
                            >
                                {probs.map(proba => (
                                    <option key={proba.id} value={proba.id} className={selectedProbIds.includes(proba.id) ? 'selected' : ''}>
                                        {proba.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>


                <List
                    columns={columns}
                    hiddenSelect={false}
                    items={items}
                    loading={loading}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                    onSelectChange={setSelectedItems}
                />
                <div className="mt-4 flex justify-center">
                    {Array.from({length: Math.ceil(totalItems / limit)}, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            style={{
                                background: offset === index ? '#3B82F6' : '#E5E7EB',
                                color: offset === index ? 'white' : undefined
                            }}
                            className={`px-4 py-2 rounded   `}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </>
            : create ? <Form item={editItem} onCancel={handleCancel} onSave={handleSave} errors={errors}
                             setErrors={setErrors}/>
                : deleteItem &&
                <DeleteConfirmation title={`Вы уверены, что хотите удалить ${deleteItem.title}?`} item={deleteItem}
                                    onConfirm={handleConfirmDelete} onCancel={handleCancelDelete}/>}
    </div>
}


// Новый компонент для управления скидками
const DiscountForm = ({onSubmit, onCancel}) => {
    const [percentage, setPercentage] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [activated, setActivated] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({percentage, startDate, endDate, activated});
    };

    return (
        <div className={classes.discountCard}>
            <h3>Управление скидками</h3>
            <form onSubmit={handleSubmit}>
                <div className={classes.formGroup}>
                    <label>Процент:</label>
                    <input
                        type="number"
                        value={percentage}
                        onChange={(e) => setPercentage(parseInt(e.target.value, 10))}
                    />
                </div>
                <div className={classes.formGroup}>
                    <label>Дата начала:</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div className={classes.formGroup}>
                    <label>Дата окончания:</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                <div className={classes.formGroup}>
                    <label>Активирована:</label>
                    <input
                        type="checkbox"
                        checked={activated}
                        onChange={(e) => setActivated(e.target.checked)}
                    />
                </div>
                <div className={classes.formActions}>
                    <button type="submit" className={classes.save}>Создать скидку</button>
                    <button type="button" onClick={onCancel} className={classes.cancel}>Отмена</button>
                </div>
            </form>
        </div>
    );
};
