import {instance} from "./https";

export class ClientService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getAll = async (search = '', limit = 10, offset = 0) => {
        const searchParam = search ? `q=${encodeURIComponent(search)}` : '';
        const limitParam = limit ? `limit=${limit}` : '';
        const offsetParam = offset ? `offset=${offset}` : '';

        const queryParams = [searchParam, limitParam, offsetParam]
            .filter(param => param)
            .join('&');

        const url = `/user?${queryParams}`;

        const response = await instance.get(url, {
            headers: {
                Authorization: this.token,
            }
        });
        return response.data;
    }

    getMe = async (userId) => {
        const response = await instance.get(`/user`, {
            headers: {
                Authorization: this.token,
            }
        });
        return response.data;
    }

    edit = async (id, item) => {
        try {
            const response = await instance.put(`/user/${id}`, item, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/user/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
}
