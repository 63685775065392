import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './DeliveryTypePage.module.scss'
import {Form} from "./Form";
import {DeliveryTypeService} from "../../service/deliveryTypeService";
import {List} from "../../сomponents/list/List";
import DeleteConfirmation from "../../сomponents/confirmDelete/DeleteConfirmation";

export const DeliveryTypePage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const deliveryTypeService = useMemo(() => new DeliveryTypeService(), [])

    const getAll = useCallback(async () => {
        setLoading(true)
        const res = await deliveryTypeService.getAll()
        console.log(res)
        if (res.success)
            setItems(res.data)
        setLoading(false)
    }, [deliveryTypeService])

    useEffect(() => {
        getAll().then().catch()
    }, [getAll]);




    const handleAddClick = () => {
        setCreate(true);
        setEditItem(null);
    }

    const handleEditClick = (item) => {
        setEditItem(item);
        setCreate(true);
    }

    const handleCancel = () => {
        setCreate(false);
        setEditItem(null);
    }

    const handleSave = async (item) => {
        if(item.id === undefined) {
            await deliveryTypeService.create(item.title, item.description, item.price)
        } else {
            await deliveryTypeService.edit(item.id, item.title, item.description, item.price)
        }
        setCreate(false);
        setEditItem(null);
        getAll()
    }

    const handleDeleteClick = (item) => {
        setDeleteItem(item);
    }

    const handleConfirmDelete = async () => {
        if (deleteItem) {
            await deliveryTypeService.delete(deleteItem.id);
            setDeleteItem(null);
            getAll();
        }
    }

    const handleCancelDelete = () => {
        setDeleteItem(null);
    }

    const columns = [
        {
            header: 'Название',
            accessor: "title",
            render: (item) => {
                return <p style={{marginLeft: '2.5rem', fontWeight: 'bold'}}>{item.title}</p>
            }
        },
        { header: 'Описание', accessor: "description" },
        { header: 'Стоимость', accessor: "price" }
    ]

    return <div className={classes.content}>
        <h2 className={classes.title}>Типы доставок</h2>

        {!create && !deleteItem ? (
            <>
                <button className={classes.save} onClick={handleAddClick} style={{marginBottom: '20px'}}>Добавить</button>
                <List columns={columns} loading={loading} items={items} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />
            </>
        ) : create ? (
            <Form
                item={editItem}
                onCancel={handleCancel}
                onSave={handleSave}
            />
        ) : deleteItem && (
            <DeleteConfirmation
                title={`Вы уверены, что хотите удалить ${deleteItem.title}?`}
                item={deleteItem}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
    </div>
}
