import React, { useRef, useState, useCallback } from "react";
import '../../styles/button.scss';
import { FileService } from "../../service/fileService";
import classes from "../../pages/product/Form.module.scss";

export const ImageManager = ({ itemImages, entity_type, entity_id, saveObject }) => {

    const [newImages, setNewImages] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);
    const [images, setImages] = useState(itemImages);
    const fileInputRef = useRef(null);
    const [mes, setMes] = useState('');
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const fileService = new FileService()

    const handleAddImage = (e) => {
        if (e.target.files.length === 0) return;

        const newFile = e.target.files[0];
        const newFileWithUrls = {
            id: 'news' + new Date().getTime(),
            file: newFile,
            entity_type: entity_type,
            path: URL.createObjectURL(newFile),
            entity_id: entity_id,
            position: images.length + newImages.length + 1,
        };

        setNewImages(prev => [...prev, newFileWithUrls]);
        setImages(prev => [...prev, newFileWithUrls]);
    };

    const handleRemoveImage = useCallback((image) => {
        setImages(prev => prev.filter(item => item.id !== image.id));

        if (!image.file) {
            setDeletedImages(prev => [...prev, image]);
        } else {
            setNewImages(prev => prev.filter(newImage => newImage.id !== image.id));
        }
    }, []);

    const loadImages = async () => {
        try {
            setLoading(true)

            await Promise.all(images.map(async (item) => {
                if (!item.file) {
                    await fileService.edit(item.id, item.position);
                }
            }));

            await Promise.all(newImages.map(async (item) => {
                const formData = new FormData();
                formData.append('entity_type', item.entity_type);
                formData.append('entity_id', item.entity_id);
                formData.append('position', item.position);
                formData.append('file', item.file, item.file.name);

                await fileService.load(formData);
            }));

            await Promise.all(deletedImages.map(async (item) => {
                await fileService.delete(item.id);
            }));

            setMes('Загружено');
        } catch (error) {
            setMes('Ошибка загрузки');
        }
        setLoading(false)
        saveObject()
    };

    const handleOpenFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const moveImage = (index, direction) => {
        const newImagesOrder = [...images];
        const targetIndex = index + direction;

        if (targetIndex < 0 || targetIndex >= images.length) return;

        [newImagesOrder[index], newImagesOrder[targetIndex]] = [newImagesOrder[targetIndex], newImagesOrder[index]];

        const updatedImages = newImagesOrder.map((img, idx) => ({
            ...img,
            position: idx + 1,
        }));

        setImages(updatedImages);
    };

    const openFullscreen = (image) => {
        setFullscreenImage(image);
    };

    const closeFullscreen = () => {
        setFullscreenImage(null);
    };

    window.setImage = (base64File) => {
        // Убираем префикс, если он есть
        const validBase64 = base64File.replace(/^data:image\/\w+;base64,/, '');

        // Преобразование base64 строки в двоичные данные
        const byteString = atob(validBase64);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // Создаем объект файла
        const file = new File([ab], 'image.jpeg', { type: 'image/jpeg' });

        const newFileWithUrls = {
            id: 'news' + new Date().getTime(),
            file: file,
            entity_type: entity_type, // Используем переданные entity_type и entity_id
            path: URL.createObjectURL(file),
            entity_id: entity_id,
            position: images.length + newImages.length + 1,
        };

        // Добавляем новый файл в состояния newImages и images
        setNewImages(prev => [...prev, newFileWithUrls]);
        setImages(prev => [...prev, newFileWithUrls]);
    }

    if(loading) return <div className={classes.loader}></div>

    return (
        <div className="w-full">
            {mes && <div className="text-center h-[50px] flex justify-center items-center text-[20px] font-bold text-[#009ef7]">{mes}</div>}
            <div className="flex flex-row flex-wrap justify-center">
                <div className='flex flex-col items-center justify-center w-[200px] h-[200px] border-2'>
                    <div className='relative flex flex-col items-center justify-center'>
                        <button data-button-id="ImageLoad" onClick={handleOpenFileDialog}
                           className={classes.save}
                           style={{
                               height: '150px',
                               width: '150px',
                               textAlign: 'center',
                               alignContent: 'center',
                               fontSize: '14px'
                           }}>
                            Добавить<br/>изображение
                        </button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleAddImage}
                            className="hidden"
                        />
                    </div>
                </div>
                {images.length !== 0 ? images.sort((a, b) => a.position - b.position).map((image, index) => (
                    <div key={image.id} className="relative flex flex-col items-center" style={{marginBottom: '2.5rem'}}>
                        <img
                            src={image.path.includes('blob') ? image.path : `https://api.uzorutum.ru/public${image.path}`}
                            alt={image.name}
                            className="object-cover rounded-md w-[200px] h-[200px] cursor-pointer border-2"
                            onClick={() => openFullscreen(image)}
                        />
                        <div className="mt-2 flex space-x-2">
                            <button onClick={() => moveImage(index, -1)} disabled={index === 0}
                                    className={index === 0 ? 'hidden' : classes.save} style={{width: '40px', height: '40px'}}>
                                {'<'}
                            </button>
                            <button onClick={() => handleRemoveImage(image)} className={classes.del}>
                                Удалить
                            </button>
                            <button onClick={() => moveImage(index, 1)} disabled={index === images.length - 1}
                                    className={index === images.length - 1 ? 'hidden' : classes.save} style={{width: '40px', height: '40px'}}>
                                {'>'}
                            </button>
                        </div>
                    </div>
                )) : <div className='flex flex-col items-center justify-center w-[200px] h-[200px] border-2'
                          style={{marginBottom: '2.5rem'}}>
                    <div className='relative flex flex-col items-center justify-center'>
                        <form action='#' name="dataFile">
                            <fieldset style={{borderWidth: 0}}>
                                Фотографий нет
                            </fieldset>
                        </form>
                    </div>
                </div>}
            </div>
            <button className={classes.save} onClick={loadImages}>
                Сохранить
            </button>
            {fullscreenImage && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
                     onClick={closeFullscreen}>
                    <img
                        src={fullscreenImage.path.includes('blob') ? fullscreenImage.path : `https://api.uzorutum.ru/public${fullscreenImage.path}`}
                        alt={fullscreenImage.name}
                        className="max-w-full max-h-full"
                    />
                </div>
            )}
        </div>
    );
};
