import {instance} from "./https";
import uuid4 from 'uuid4'


export class CartService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getUser = async (user_id) => {
        const response = await instance.get(`/carts/check?token=${uuid4()}&user_id=${user_id}`, {
            headers: {
                Authorization: this.token,
                accept: 'application/json'
            }
        });
        return response.data;
    }
}
