import React from 'react';
import classes from './DeleteConfirmation.module.scss';

const DeleteConfirmation = ({ title, onConfirm, onCancel }) => {
    console.log(title)
    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
        <div className={classes.cart}>
            <div className={classes.header}>
                <div className={classes.title}>
                    <h2>{title}</h2>
                </div>
            </div>
            <div className={classes.body}>
                <div className={classes.responsive}>
                    <table className={classes.table}>
                        <tbody className={classes.tbody}>
                        <div className={classes.formActions}>
                            <button onClick={(item) => onConfirm(item)} className={classes.save}>Да, удалить</button>
                            <button style={{marginTop: '10px'}} onClick={(item) => onCancel(item)}
                                    className={classes.cancel}>Отмена
                            </button>
                        </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}

export default DeleteConfirmation;
