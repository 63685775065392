export const News = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_7282)">
            <path
                d="M3.00629e-06 2.5C3.00629e-06 2.10218 0.158038 1.72064 0.439343 1.43934C0.720647 1.15804 1.10218 1 1.5 1H12.5C12.8978 1 13.2794 1.15804 13.5607 1.43934C13.842 1.72064 14 2.10218 14 2.5V13.028C14 13.328 13.95 13.682 13.762 14H14.5C14.6326 14 14.7598 13.9473 14.8536 13.8536C14.9473 13.7598 15 13.6326 15 13.5V4.5C15 4.36739 15.0527 4.24021 15.1464 4.14645C15.2402 4.05268 15.3674 4 15.5 4C15.6326 4 15.7598 4.05268 15.8536 4.14645C15.9473 4.24021 16 4.36739 16 4.5V13.5C16 13.8978 15.842 14.2794 15.5607 14.5607C15.2794 14.842 14.8978 15 14.5 15H1.497C1.30016 15 1.10525 14.9612 0.923432 14.8858C0.741612 14.8103 0.576451 14.6998 0.437402 14.5605C0.298354 14.4212 0.188148 14.2558 0.113093 14.0738C0.0380385 13.8918 -0.000391466 13.6968 3.00629e-06 13.5V2.5ZM12 14C12.37 14 12.654 13.789 12.853 13.559C12.945 13.453 13 13.28 13 13.028V2.5C13 2.36739 12.9473 2.24021 12.8536 2.14645C12.7598 2.05268 12.6326 2 12.5 2H1.5C1.36739 2 1.24022 2.05268 1.14645 2.14645C1.05268 2.24021 1 2.36739 1 2.5V13.5C1 13.778 1.223 14 1.497 14H12Z"
                fill="white"/>
            <path
                d="M2 3H12V5H2V3ZM2 6H6V9H2V6ZM2 10H6V11H2V10ZM2 12H6V13H2V12ZM7 6H9V7H7V6ZM10 6H12V7H10V6ZM7 8H9V9H7V8ZM10 8H12V9H10V8ZM7 10H9V11H7V10ZM10 10H12V11H10V10ZM7 12H9V13H7V12ZM10 12H12V13H10V12Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_2_7282">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}
