import {instance} from "./https";

export class ProductService {
    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getAll = async (search = '', limit = 10, offset = 0, category_id, material_id, probIds = [], decorationIds = [], sizeIds = [] ) => {
        const searchParam = search ? `q=${encodeURIComponent(search)}` : '';
        const limitParam = limit ? `limit=${limit}` : '';
        const offsetParam = offset ? `offset=${offset}` : '';
        const categoryParam = category_id ? `categoryId=${category_id}` : '';
        const materialParam = material_id ? `materialId=${material_id}` : '';
        const probParam = probIds.length ? `probIds=${probIds.join(',')}` : '';
        const decorationParam = decorationIds.length ? `decorationIds=${decorationIds.join(',')}` : '';
        const sizeParam = sizeIds.length ? `sizeIds=${sizeIds.join(',')}` : '';

        const queryParams = [searchParam, limitParam, offsetParam, categoryParam, materialParam, probParam, decorationParam, sizeParam]
            .filter(param => param)
            .join('&');

        const url = `/products?${queryParams}`;

        const response = await instance.get(url, {
            headers: {
                Authorization: this.token,
            }
        });
        return response.data;
    }

    save = async (data) => {
        try {
            const response = await instance.post(`/products`, data, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    edit = async (id, data) => {
        try {
            const response = await instance.put(`/products/${id}`, data, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/products/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
}
