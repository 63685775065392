import React, {useState} from "react";
import classes from "./Form.module.scss";

export const Form = ({item, onCancel, onSave }) => {
    const [code, setCode] = useState(item ? item.code : '');
    const [description, setDescription] = useState(item ? item.description : '');
    const [active, setActive] = useState(item ? item.active : '');
    const [discount, setDiscount] = useState(item ? item.discount : '');
    const [validFrom, setValidFrom] = useState(item ? item.valid_from ? item.valid_from.split('T')[0] : '' : '');
    const [validTo, setValidTo] = useState(item ? item.valid_to ? item.valid_to.split('T')[0] : '' : '');


    const handleSubmit = () => {
        const category = { id: item?.id, code, description, active, discount, valid_from: new Date(validFrom), valid_to: new Date(validTo) };
        onSave(category); // вызываем функцию сохранения
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
            <div className={classes.formActions}>
                <button style={{marginTop: '10px'}} className={classes.cancel} onClick={onCancel}>Назад</button>
            </div>
            <div className={classes.cart} style={{width: '100%'}}>
                <div className={classes.header}>
                    <div className={classes.title}>
                        <h2>Информация</h2>
                    </div>
                </div>
                <div className={classes.body}>
                    <div className={classes.responsive}>
                        <table className={classes.table}>
                            <tbody className={classes.tbody}>
                            <tr>
                                <td>Код</td>
                                <td>
                                    <input
                                        type="text"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        placeholder="Код"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Описание</td>
                                <td>
                                    <textarea
                                        type="text"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder="Описание"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Скидка</td>
                                <td>
                                    <input
                                        type="text"
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                        placeholder="Скидка"
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={classes.cart} style={{width: '100%', marginTop: '2.5rem'}}>
                <div className={classes.header}>
                    <div className={classes.title}>
                        <h2>Служебная информация</h2>
                    </div>
                </div>
                <div className={classes.body}>
                    <div className={classes.responsive}>
                        <table className={classes.table}>
                            <tbody className={classes.tbody}>
                            <tr>
                                <td>Активен с</td>
                                <td>
                                    <input
                                        type="date"
                                        value={validFrom}
                                        onChange={(e) => setValidFrom(e.target.value)}
                                        placeholder="Активен с"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Активен до</td>
                                <td>
                                    <input
                                        type="date"
                                        value={validTo}
                                        onChange={(e) => setValidTo(e.target.value)}
                                        placeholder="Активен до"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Статус активности</td>
                                <td>
                                    <div className={classes.toggleSwitch}>
                                        <input
                                            type="checkbox"
                                            id="toggleSwitch"
                                            checked={active}  // это ваш boolean state
                                            onChange={(e) => setActive(e.target.checked)}
                                        />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <button className={classes.save} onClick={handleSubmit  }>Добавить</button>
        </div>
    );
}
