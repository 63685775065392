import InputMask from "react-input-mask";
import React, {useState} from "react";
import classes from './phone.module.scss';
import {instance} from "../../service/https";

export function Phone({ setStep, setPhoneSave }) {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [inputError, setInputError] = useState(false);

    const handlePhoneSubmit = async () => {
        setIsLoading(true);
        setErrorMessage("");
        setInputError(false);

        try {
            const res = await instance.post('/auth/login', {
                phone: phoneNumber,
            });
            console.log(res)

            if (res.status === 200 && res.data.success) {
                setPhoneSave(phoneNumber)
                setTimeout(() => {
                    setStep(prev => prev + 1);
                }, 500);
            }
        } catch (err) {
            console.log(err)
            if (err.response.status === 400 || err.response.status === 404) {
                const message = err.response.data.message instanceof Array
                    ? err.response.data.message[0].message
                    : err.response.data.message;
                setErrorMessage(message);
                setInputError(true);
            } else if (err.response.status === 500) {
                setErrorMessage("Ошибка на сервере. Попробуйте позже.");
                setInputError(true);
            } else {
                setErrorMessage("Произошла ошибка. Попробуйте снова.");
                setInputError(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="h-[100%] p-[20px] bg-[#f4f4f4] flex flex-col items-center justify-center">
            <h2>Введите номер телефона</h2>
            <InputMask
                type="text"
                value={phoneNumber}
                mask="+9(999)999-99-99"
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Телефон"
                className={`${classes.phone} ${inputError ? classes.errorInput : ""}`}
            />
            {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}

            {isLoading ? <div className={classes.loader}></div>  : <button onClick={handlePhoneSubmit} className={`${classes.btn} ${phoneNumber.length <= 0 ? classes.disable : ''}`} disabled={phoneNumber.length <= 0}>Отправить</button> }
        </div>
    );
}
