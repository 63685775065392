import React, {useState} from "react";
import classes from "./Form.module.scss";

export const Form = ({item, onCancel, onSave, types, clients}) => {
    const [phone, setPhone] = useState(item ? item.phone : '');
    const [email, setEmail] = useState(item ? item.email : '');
    const [accepted, setAccepted] = useState(item ? item.accepted : false);
    const [activated, setActivated] = useState(item ? item.activated : false);
    const [deliveryAt, setDeliveryAt] = useState(item ? item.delivery_at ? item.delivery_at?.split('T')[0] : '' : '');
    const [userId, setUserId] = useState(item ? item.user_id : undefined);
    const [code, setCode] = useState(item ? item.code : '');
    const [certificateTypeId, setCertificateTypeId] = useState(item ? item.certificate_type_id : '');


    const handleSubmit = () => {
        const category = { id: item?.id, phone, email, accepted, delivery_at: deliveryAt, user_id: userId, certificate_type_id: certificateTypeId };
        onSave(category); // вызываем функцию сохранения
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
            <div className={classes.formActions}>
                <button style={{marginTop: '10px'}} className={classes.cancel} onClick={onCancel}>Назад</button>
            </div>
            <div className={classes.row}>
                <div className={classes.cart}>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <h2>Контактная информация</h2>
                        </div>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.responsive}>
                            <table className={classes.table}>
                                <tbody className={classes.tbody}>
                                <tr>
                                    <td>Телефон</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            placeholder="Телефон"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Почта</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Почта"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <button className={classes.save} onClick={handleSubmit}>Сохранить</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className={classes.cart}>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <h2>Сервисная информация</h2>
                        </div>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.responsive}>
                            <table className={classes.table}>
                                <tbody className={classes.tbody}>
                                <tr>
                                    <td>Код</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                            placeholder="Телефон"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Время отправки</td>
                                    <td>
                                        <input
                                            type="date"
                                            value={deliveryAt}
                                            onChange={(e) => setDeliveryAt(e.target.value)}
                                            placeholder="Время когда отправить"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Номинал</td>
                                    <td>
                                        <select>
                                            {types.map(cat => (
                                                <option key={cat.id} onClick={() => setCertificateTypeId(cat.id)}
                                                        selected={cat.id === certificateTypeId}
                                                        value={cat.id}>{cat.value}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Пользователь</td>
                                    <td>
                                        <select>
                                            <option value="">Выберите пользователя</option>
                                            {clients.map(cat => {
                                                return <option key={cat.id} onClick={() => setUserId(cat.id)}
                                                               selected={cat.id === userId}
                                                               value={cat.id}>{cat.first_name + " " + cat.last_name}</option>
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Статус</td>
                                    <td>
                                        <div className={classes.checkboxWrapper}>
                                            <input
                                                type="checkbox"
                                                checked={activated}
                                                onChange={(e) => setActivated(e.target.checked)}
                                                id="acceptedCheckbox"
                                            />
                                            <label htmlFor="acceptedCheckbox">{activated ? 'Использован' : 'Еще не использован'}</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Согласие на персональные данные</td>
                                    <td>
                                        <div className={classes.checkboxWrapper}>
                                            <input
                                                type="checkbox"
                                                checked={accepted}
                                                onChange={(e) => setAccepted(e.target.checked)}
                                                id="acceptedCheckbox"
                                            />
                                            <label htmlFor="acceptedCheckbox">Подтвердил согласие на обработку
                                                персональных
                                                данных</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <button className={classes.save} onClick={handleSubmit}>Сохранить</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
