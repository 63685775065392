import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './DeliveryPage.module.scss'
import {OrderService} from "../../service/orderService";

export const DeliveryPage = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const statusesList = [
        {title: 'PENDING', ru: 'Ожидает'},
        {title: 'PAIDING', ru: 'Оплачен'},
        {title: 'BUILDING', ru: 'Сборка'},
        {title: 'SENDING', ru: 'В пути'},
        {title: 'AWAITING', ru: 'Ожидает получателя'},
        {title: 'SUCCESSED', ru: 'Завершено'},
        {title: 'CANCELLED', ru: 'Отменен'}
    ]

    const [statuses, setStatuses] = useState(['PENDING', 'PAIDING', 'BUILDING', 'SENDING', 'AWAITING']);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [editedStatus, setEditedStatus] = useState({});

    const orderService = useMemo(() => new OrderService(), [])

    const getAll = useCallback(async () => {
        setLoading(true);
        const res = await orderService.getAll(undefined, statuses, search, undefined, limit, offset);
        console.log(res);
        if (res.success) {
            setItems(res.data);
            setTotalItems(res.pagination.totalItems); // Предполагается, что сервер возвращает общее количество записей
        }
        setLoading(false);
    }, [orderService, statuses, search, limit, offset])

    useEffect(() => {
        getAll();
    }, [getAll, statuses, search, limit, offset]);



    // Обработчики изменения статусов, поиска, пагинации
    const handleStatusChange = (status) => {
        if (statuses.includes(status)) {
            setStatuses(statuses.filter(s => s !== status));
        } else {
            setStatuses([...statuses, status]);
        }
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleLimitChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
    };

    const handlePageChange = (page) => {
        setOffset(page - 1);
    };

    const handleEditStatusChange = (id, newStatus) => {
        setEditedStatus({ ...editedStatus, [id]: newStatus });
    };

    const handleSaveStatus = async (id) => {
        const newStatus = editedStatus[id];
        if (newStatus) {
            setLoading(true);
            const success = await orderService.edit(id, {status: newStatus});
            if (success) {
                // Обновляем статус в списке заказов локально
                setItems(items.filter(item => item.id !== id));
            }
            setLoading(false);
        }
    };

    return <>
        <h2 className="text-2xl font-bold mb-4">Доставка</h2>

        {/* Фильтры и поиск */}

        <div className="flex flex-col md:flex-row justify-between mb-4">
            <div className="flex space-x-2">
                <input
                    type="text"
                    value={search}
                    onChange={handleSearchChange}
                    placeholder="Поиск..."
                    className="px-4 py-2 rounded border border-gray-300"
                />
                <select
                    value={limit}
                    onChange={handleLimitChange}
                    className="px-4 py-2 rounded border border-gray-300"
                >
                    {[10, 20, 50].map(limitOption => (
                        <option key={limitOption} value={limitOption}>{limitOption} на странице</option>
                    ))}
                </select>
            </div>
        </div>
        <div className="flex space-x-2 overflow-scroll mb-4">
            {statusesList.map(status => (
                <button
                    key={status.title}
                    onClick={() => handleStatusChange(status.title)}
                    style={{
                        background: statuses.includes(status.title) ? '#3B82F6' : '#E5E7EB',
                        color: statuses.includes(status.title) ? 'white' : undefined
                    }}
                    className={`px-4 py-2 rounded `}
                >
                    {status.ru}
                </button>
            ))}
        </div>

        {/* Контент */}
        {!loading ? (
            <div className="flex flex-col space-y-4">
                {items.map(item => (
                    <div key={item.id} className="p-4 bg-white rounded shadow">
                        <div className="flex justify-between">
                            <h3 className="text-xl font-bold">Заказ #{item.id.split('-').pop()}</h3>
                            <p className="text-gray-500">Создано: {new Date(item.created_at).toLocaleString()}</p>
                        </div>
                        <div className="mt-2 text-gray-700">
                            <p><strong>ФИО:</strong> {item.first_name} {item.last_name}</p>
                            <p><strong>Телефон:</strong> {item.phone}</p>
                            <p><strong>Дата изменений:</strong> {new Date(item.updated_at).toLocaleString()}</p>
                        </div>
                        <div className="mt-2 text-gray-700">
                            <label className="block text-gray-700">Изменить статус:</label>
                            <div className="flex items-center space-x-2">
                                <select
                                    value={editedStatus[item.id] || item.status}
                                    onChange={(e) => handleEditStatusChange(item.id, e.target.value)}
                                    className="px-4 py-2 rounded border border-gray-300"
                                >
                                    {statusesList.map(status => (
                                        <option key={status.title} value={status.title}>{status.ru}</option>
                                    ))}
                                </select>
                                <button
                                    onClick={() => handleSaveStatus(item.id)}
                                    className="px-4 py-2 bg-blue-500 text-white rounded"
                                >
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        ) : (
            <div className="flex justify-center items-center">
                <div className={classes.loader}></div>
            </div>
        )}

        {/* Пагинация */}
        <div className="mt-4 flex justify-center">
            {Array.from({length: Math.ceil(totalItems / limit)}, (_, index) => (
                <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    style={{
                        background: offset === index ? '#3B82F6' : '#E5E7EB',
                        color: offset === index ? 'white' : undefined
                    }}
                    className={`px-4 py-2 rounded   `}
                >
                    {index + 1}
                </button>
            ))}
        </div>
    </>
}




