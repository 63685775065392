import React, {useState} from "react";
import classes from "./Form.module.scss";
import {ImageManager} from "../../сomponents/imageManager/ImageManager";

export const Form = ({item, onCancel, onSave, errors, setErrors}) => {
    const [title, setTitle] = useState(item ? item.title : '');

    const handleSubmit = () => {
        setErrors([])
        const category = { id: item?.id, title };
        onSave(category); // вызываем функцию сохранения
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
            <div className={classes.formActions}>
                <button style={{marginTop: '10px'}} className={classes.cancel} onClick={onCancel}>Назад</button>
            </div>
                <div className={classes.row}>
                    <div className={classes.cart} style={{maxWidth: '400px'}}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Галерея</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    <ImageManager
                                        itemImages={item ? item.images : []}
                                        entity_type={'category'}
                                        entity_id={item ? item.id : ''}
                                        saveObject={handleSubmit}
                                    />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cart}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Информация</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    <tr>
                                        <td>Название</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={title}
                                                onChange={(e) => {
                                                    setTitle(e.target.value)
                                                    setErrors([])
                                                }}
                                                placeholder="Название"
                                                className={errors.find(err => err.type === 'title') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'title') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'title').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <button className={classes.save} onClick={handleSubmit}>Сохранить</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
}
