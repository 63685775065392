import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './ClientPage.module.scss'
import {ClientService} from "../../service/clientService";
import {Form} from "./Form";
import {List} from "../../сomponents/list/List";
import DeleteConfirmation from "../../сomponents/confirmDelete/DeleteConfirmation";

export const ClientPage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const clientService = useMemo(() => new ClientService(), []);


    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const getAll = useCallback(async () => {
        setLoading(true)
        const res = await clientService.getAll(search, limit, offset)
        if (res.success) {
            setItems(res.data)
            setTotalItems(res.pagination.totalItems);
        }
        console.log(res.data)
        setLoading(false)
    }, [clientService, search, limit, offset]);

    useEffect(() => {
        getAll().then().catch()
    }, [getAll, search, limit, offset]);

    const handleEditClick = (item) => {
        setEditItem(item);
        setCreate(true);
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleLimitChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
    };

    const handlePageChange = (page) => {
        setOffset(page - 1);
    };

    const handleCancel = () => {
        setCreate(false);
        setEditItem(null);
    }

    const handleSave = async (item) => {
        await clientService.edit(item.id, item)
        setCreate(false);
        setEditItem(null);
        getAll()
    }

    const handleDeleteClick = (item) => {
        setDeleteItem(item);
    }

    const handleConfirmDelete = async () => {
        if (deleteItem) {
            try {
                await clientService.delete(deleteItem.id);
                setDeleteItem(null);
                getAll();
            } catch (err) {
                alert(err)
            }
        }
    }

    const handleCancelDelete = () => {
        setDeleteItem(null);
    }

    const columns = [
        {
            header: 'ФИО',
            render: (item) => {
                return <p style={{fontWeight: 'bold'}}>{item.first_name + " " + item.last_name}</p>
            }
        },
        {header: 'Почта', accessor: 'email'},
        {header: 'Телефон', accessor: 'phone'},
        {
            header: 'Дата регистрации',
            accessor: 'created_at',
            render: item => {
                return new Date(item.created_at).toLocaleDateString() + " " + new Date(item.created_at).toLocaleTimeString()
            },
        },
    ]

    return <div className={classes.content}>
        <h2 className={classes.title}>Пользователи</h2>

        {!create && !deleteItem ? (
            <>
                <div className="flex flex-col md:flex-row justify-between mb-4">
                    <div className="flex space-x-2">

                    </div>
                    <div className="flex space-x-2">
                        <input
                            type="text"
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="Поиск..."
                            className="px-4 py-2 rounded border border-gray-300"
                        />
                        <select
                            value={limit}
                            onChange={handleLimitChange}
                            className="px-4 py-2 rounded border border-gray-300"
                        >
                            {[10, 20, 50].map(limitOption => (
                                <option key={limitOption} value={limitOption}>{limitOption} на странице</option>
                            ))}
                        </select>
                    </div>
                </div>
                <List columns={columns} loading={loading} items={items} handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}/>
                <div className="mt-4 flex justify-center">
                    {Array.from({length: Math.ceil(totalItems / limit)}, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            style={{
                                background: offset === index ? '#3B82F6' : '#E5E7EB',
                                color: offset === index ? 'white' : undefined
                            }}
                            className={`px-4 py-2 rounded   `}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </>
        ) : create ? (
            <Form
                item={editItem}
                onCancel={handleCancel}
                onSave={handleSave}
            />
        ) : deleteItem && (
            <DeleteConfirmation
                title={`Вы уверены, что хотите удалить ${deleteItem.first_name + " " + deleteItem.last_name}?`}
                item={deleteItem}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
    </div>
}
