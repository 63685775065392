import { createRoot } from 'react-dom/client';
import { AuthProvider } from './context/AuthContext';
import MainPage from './pages/admin/mainPage';
import './index.css';

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
    <AuthProvider>
        <MainPage />
    </AuthProvider>
);
