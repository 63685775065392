export const Home = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2.00002 13.4998V6.99982H3.00002V13.4998C3.00002 13.6324 3.0527 13.7596 3.14647 13.8534C3.24024 13.9471 3.36741 13.9998 3.50002 13.9998H12.5C12.6326 13.9998 12.7598 13.9471 12.8536 13.8534C12.9473 13.7596 13 13.6324 13 13.4998V6.99982H14V13.4998C14 13.8976 13.842 14.2792 13.5607 14.5605C13.2794 14.8418 12.8978 14.9998 12.5 14.9998H3.50002C3.1022 14.9998 2.72067 14.8418 2.43936 14.5605C2.15806 14.2792 2.00002 13.8976 2.00002 13.4998ZM13 2.49982V5.99982L11 3.99982V2.49982C11 2.36721 11.0527 2.24003 11.1465 2.14626C11.2402 2.0525 11.3674 1.99982 11.5 1.99982H12.5C12.6326 1.99982 12.7598 2.0525 12.8536 2.14626C12.9473 2.24003 13 2.36721 13 2.49982Z"
              fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M7.29302 1.49982C7.48055 1.31235 7.73486 1.20703 8.00002 1.20703C8.26519 1.20703 8.51949 1.31235 8.70702 1.49982L15.354 8.14582C15.4479 8.2397 15.5007 8.36704 15.5007 8.49982C15.5007 8.63259 15.4479 8.75993 15.354 8.85382C15.2601 8.9477 15.1328 9.00045 15 9.00045C14.8672 9.00045 14.7399 8.9477 14.646 8.85382L8.00002 2.20682L1.35402 8.85382C1.26013 8.9477 1.1328 9.00045 1.00002 9.00045C0.867245 9.00045 0.739908 8.9477 0.646021 8.85382C0.552135 8.75993 0.49939 8.63259 0.49939 8.49982C0.49939 8.36704 0.552135 8.2397 0.646021 8.14582L7.29302 1.49982Z"
              fill="white"/>
    </svg>

}
