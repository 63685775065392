import React, {useEffect, useState} from "react";
import classes from './confirmCode.module.scss';
import {instance} from "../../service/https";
import {AuthService} from "../../service/authService";

export function ConfirmCode({ setStep, phoneSave }) {

    const [otp, setOtp] = useState(Array(5).fill(""));
    const [errorMessage, setErrorMessage] = useState("");
    const [inputError, setInputError] = useState(false);
    const [canResend, setCanResend] = useState(false);
    const [timer, setTimer] = useState(60);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let interval;

        if (!canResend && timer > 0) {
            interval = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            setCanResend(true);
            clearInterval(interval);
        }

        // Cleanup function to clear the interval and avoid memory leaks
        return () => {
            clearInterval(interval);
        };
    }, [canResend, timer]);

    const handleChange = (e, index) => {
        const value = e.target.value;

        if (value.match(/^[0-9]$/)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < 4) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            } else {
                handleCodeSubmit(newOtp.join(""));
            }
        } else if (value === "") {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index > 0) {
                const previousInput = document.getElementById(`otp-input-${index - 1}`);
                previousInput.focus();
                setTimeout(() => previousInput.select(), 0);
                setIsLoading(false)
            }
        }
    };

    const handleCodeSubmit = async (code) => {
        setIsLoading(true);
        let isMounted = true;


        console.log(code)

        try {
            const res = await instance.post('/auth/verify', { code: code });

            if (isMounted) {
                if (res.status === 200 && res.data.success) {
                    const token = res.data.data.token;
                    const authService = new AuthService("Bearer " + token);
                    const result = await authService.checkAuth();

                    console.error(result)

                    if (result) {
                        localStorage.setItem("token", "Bearer " + token);
                        setStep(prev => prev + 1);
                    } else {
                        setErrorMessage("Ошибка авторизации");
                        setInputError(true);
                        setOtp(Array(5).fill("")); // Сброс кода
                        document.getElementById(`otp-input-0`).focus();
                        setIsLoading(false);
                    }
                } else if (res.status === 400 || res.status === 404) {
                    const message = Array.isArray(res.data.message)
                        ? res.data.message[0].message
                        : res.data.message;
                    setErrorMessage(message);
                    setInputError(true);
                    setOtp(Array(5).fill("")); // Сброс кода
                    document.getElementById(`otp-input-0`).focus();
                    setIsLoading(false);
                } else if (res.status === 500) {
                    setErrorMessage("Ошибка на сервере. Попробуйте позже.");
                    setInputError(true);
                    setOtp(Array(5).fill("")); // Сброс кода
                    document.getElementById(`otp-input-0`).focus();
                    setIsLoading(false);
                }
            }
        } catch (error) {
            if (isMounted) {
                setErrorMessage("Произошла ошибка при отправке кода. Пожалуйста, попробуйте снова.");
                setInputError(true);
                setOtp(Array(5).fill(""));
                setIsLoading(false);
            }
        } finally {
            if (isMounted) {
                setIsLoading(false);
            }
        }

        // Cleanup function to prevent setting state if the component is unmounted
        return () => {
            isMounted = false;
        };
    };

    const handleResendCode = async () => {
        if (canResend) {

            const res = await instance.post('/auth/login', {
                phone: phoneSave,
            });
            if (res.status === 200 && res.data.success) {
                setTimer(60);
                setCanResend(false);
            }
        }
    };

    const handleChangePhone = () => {
        setStep(prev => prev - 1);
    };

    return (
        <div className={classes.content}>
            <h2>Введите код подтверждения</h2>
            <div className={classes.otpContainer}>
                {otp.map((digit, index) => (
                    <input
                        key={index}
                        id={`otp-input-${index}`}
                        type="number"
                        value={digit}
                        onChange={(e) => {
                            setInputError(false)
                            setErrorMessage("")
                            handleChange(e, index)
                        }}
                        maxLength="1"
                        className={`w-[50px] h-[50px] my-0 mx-[5px] text-[24px] text-center border-[1px] border-solid border-[#ccc] rounded-[5px] focus:border-[#007bff] focus:outline-none focus:shadow-[0 0 5px rgba(0, 123, 255, 0.5)] ${inputError ? "border-[red]" : ""}`}
                    />
                ))}
            </div>
            {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}

            {isLoading ? (
                <div className={classes.loader}></div>
            ) : (
                <>
                    {!canResend ? (
                        <p className={classes.timerText}>Повторно отправить код через {timer} секунд</p>
                    ) : (
                        <button onClick={handleResendCode}>
                            Повторно отправить код
                        </button>
                    )}
                    <button onClick={handleChangePhone}>
                        Изменить телефон
                    </button>
                </>
            )}
        </div>
    );
}
