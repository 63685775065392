import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './PromoCodePage.module.scss'
import {Form} from "./Form";
import {PromoCodeService} from "../../service/promocodeService";
import StatusIndicator from "../../сomponents/statusIndicator/StatusIndicator";
import {List} from "../../сomponents/list/List";
import DeleteConfirmation from "../../сomponents/confirmDelete/DeleteConfirmation";

export const PromoCodePage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const promoCodeService = useMemo(() => new PromoCodeService(), []);

    const getAll = useCallback(async () => {
        setLoading(true)
        const res = await promoCodeService.getAll()
        console.log(res)
        if (res.success) {
            const certs = res.data
            setItems(certs)
        }

        setLoading(false)
    }, [promoCodeService]);


    useEffect(() => {
        getAll().then().catch();
    }, [getAll]);

    const handleAddClick = () => {
        setCreate(true);
        setEditItem(null);
    }

    const handleEditClick = (item) => {
        setEditItem(item);
        setCreate(true);
    }

    const handleCancel = () => {
        setCreate(false);
        setEditItem(null);
    }

    const handleSave = async (item) => {
        if(item.id === undefined) {
            await promoCodeService.create(item.code, item.description, item.active, item.discount, item.valid_from, item.valid_to)
        } else {
            await promoCodeService.edit(item.id, item.code, item.description, item.active, item.discount, item.valid_from, item.valid_to)
        }
        setCreate(false);
        setEditItem(null);
        getAll()
    }

    const handleDeleteClick = (item) => {
        setDeleteItem(item);
    }

    const handleConfirmDelete = async () => {
        if (deleteItem) {
            try {
                await promoCodeService.delete(deleteItem.id);
                setDeleteItem(null);
                getAll();
            } catch (err) {
                alert(err)
            }
        }
    }

    const handleCancelDelete = () => {
        setDeleteItem(null);
    }

    const columns = [
        {header: 'Код', accessor: "code"},
        {header: 'Описание', accessor: "description"},
        {header: 'Активен', accessor: "active", render: item => {
            return <StatusIndicator status={item.active} />
            }},
        {header: 'Скидка', accessor: "discount", render: item => {
            return item.discount + "%"
            }},
        {header: 'Работает от', accessor: "valid_from", render: item => {
            return new Date(item.valid_from).toLocaleDateString() + " " + new Date(item.valid_from).toLocaleTimeString()
            }},
        {header: 'Работает до', accessor: "valid_to", render: item => {
            return new Date(item.valid_to).toLocaleDateString() + " " + new Date(item.valid_to).toLocaleTimeString()
            }},
    ]

    return <div className={classes.content}>
        <h2 className={classes.title}>Промокоды</h2>

        {!create && !deleteItem ? (
            <>
                <button className={classes.addBtn} onClick={handleAddClick}>Добавить</button>
                <List columns={columns} loading={loading} items={items} handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}/>
            </>
        ) : create ? (
            <Form
                item={editItem}
                onCancel={handleCancel}
                onSave={handleSave}
            />
        ) : deleteItem && (
            <DeleteConfirmation
                title={`Вы уверены, что хотите удалить сертификат для ${deleteItem.phone} по номиналу ${deleteItem.certificate.value}₽?`}
                item={deleteItem}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
    </div>
}
