import React, {useContext, useEffect, useState} from "react";
import {Phone} from "../../сomponents/auth/phone";
import {ConfirmCode} from "../../сomponents/auth/confirmCode";
import {AuthContext} from "../../context/AuthContext";

export function AuthScreen() {
    const {setIsAuth} = useContext(AuthContext);
    const [step, setStep] = useState(0);
    const [phoneSave, setPhoneSave] = useState('')

    // Trigger authentication when the step indicates completion
    useEffect(() => {
        if (step === 2) {
            setIsAuth(true);
        }
    }, [step, setIsAuth]);


    return <>
        {step === 0 ? (
            <Phone setStep={setStep} setPhoneSave={setPhoneSave} />
        ) : step === 1 ? (
            <ConfirmCode setStep={setStep} phoneSave={phoneSave}  />
        ) : null}
    </>
}
