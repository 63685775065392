import {instance} from "./https";

export class OrderService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getAll = async (user_id, statuses, search = '', send_type_id = '', limit = 10, offset = 0, createdAt) => {
        const userParam = user_id ? `user_id=${user_id}` : '';
        const statusParam = statuses && statuses.length ? statuses.map(status => `status=${status}`).join('&') : '';
        const searchParam = search ? `q=${encodeURIComponent(search)}` : '';
        const sendTypeParam = send_type_id ? `send_type_id=${send_type_id}` : '';
        const limitParam = limit ? `limit=${limit}` : '';
        const offsetParam = offset ? `offset=${offset}` : '';
        const createdParam = createdAt ? `created_at=${createdAt}` : '';

        const queryParams = [userParam, statusParam, searchParam, sendTypeParam, limitParam, offsetParam, createdParam]
            .filter(param => param)
            .join('&');

        const url = `/order?${queryParams}`;

        const response = await instance.get(url, {
            headers: {
                Authorization: this.token,
                accept: 'application/json'
            }
        });
        return response.data;
    }

    getById = async (id) => {
        const response = await instance.get(`/order/${id}`, {
            headers: {
                Authorization: this.token,
                accept: 'application/json'
            }
        });
        return response.data;
    }

    create = async (phone, email, accepted, delivery_at, user_id, certificate_type_id) => {
        try {
            const response = await instance.post("/order", {
                phone,
                email,
                accepted,
                delivery_at,
                user_id,
                certificate_type_id,
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    edit = async (id, item) => {
        try {
            const response = await instance.put(`/order/${id}`, item, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/order/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
}
