import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from "./Form.module.scss";
import {OrderService} from "../../service/orderService";
import {getStatus} from "../../сomponents/support/getStatus";

export const Form = ({item, onCancel, onSave}) => {
    const [data, setData] = useState({})

    const [loading, setLoading] = useState(true);

    const getAddress = () => {
        if(!!data.shop_id && !!data.shop_data)
            return <>
                <b>Магазин:</b>
                <br/>
                {data.shop_data.address}
            </>
        if(!!!data.shop_id)
            return <>
                {data.address}
                <br/>
                Дом: {data.house}
                <br/>
                {!!data.apartment && 'Квартира ' + data.apartment + '<br/>'}
                Индекс: {data.postal_code}
                <br/>
                {!!data.office && 'Кабинет ' + data.office + '<br/>'}
                {!!data.delivery_at && 'Время доставки ' + data.delivery_at + '<br/>'}

            </>
    }

    const getPaymentType = (type) => {
        const types = {
            bank_card: 'Банковская карта',
            sbp: 'СБП',
            yoo_money: 'Юкасса',
            tinkoff_bank: 'Т-Банк',
            sberbank: 'Сбербанк',
            mobile_balance: 'Мобильный баланс',
            undefined: 'Отказ'
        }
        return <div>{types[type]}</div>
    }

    const orderService = useMemo(() => new OrderService(), [])

    const fetchData = useCallback(async () => {
        try {
            setLoading(true)
            const [data] = await Promise.all([
                orderService.getById(item.id),
            ]);

            if(data.success)
                setData(data.data)

            console.log(data.data)

        } catch (error) {
            console.error("Ошибка загрузки данных:", error);
        } finally {
            setLoading(false); // завершение загрузки
        }
    }, [orderService, item])

    useEffect(() => {
        fetchData().then().catch()
    }, [fetchData]);



    const handleSubmit = () => {
        // const prodData = {id: item?.id, title, article, price, available, sex, description, details, delivery, category_id: categoryId, material_id: materialId, prob_ids: proba, decoration_ids: decor, size_ids: size};
        // onSave(prodData);
    }

    const getSum = () => {
        let result = 0;
        for (const item of data.items) {
            const res = item.product?.discount
                ? Math.floor(item.product.price * (100 - item.product.discount.percentage) / 100) * item.count
                : Math.floor(item.product.price * item.count);
            console.log(res);
            result += res;
        }
        console.log(result);
        return result;
    }


    if (loading) {
        return <div className={classes.loader}></div>; // можно заменить на более красивую анимацию
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
            <div className={classes.formActions}>
                <button className={classes.cancel} onClick={onCancel}>Назад</button>
                <button className={classes.save} onClick={handleSubmit}>Сохранить</button>
            </div>
            <div className={classes.column}>
                <div className={classes.row}>
                    <div className={classes.cart}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Детали заказа (#{item.id.split('-')[item.id.split('-').length - 1]})</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    <tr>
                                        <td>Дата создания</td>
                                        <td>{new Date(data.created_at).toLocaleDateString() + " " + new Date(data.created_at).toLocaleTimeString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Способ оплаты</td>
                                        <td>{getPaymentType(data.payment_data.data?.payment_method?.type || 'undefined') }</td>
                                    </tr>
                                    <tr>
                                        <td>Дата изменений</td>
                                        <td>{new Date(data.updated_at).toLocaleDateString() + " " + new Date(data.updated_at).toLocaleTimeString()}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cart}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Данные заказчика</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    <tr>
                                        <td>Клиент</td>
                                        <td>{data.first_name + " " + data.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Почта</td>
                                        <td>{data.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Телефон</td>
                                        <td>{data.phone}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cart}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Дополнительно</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    <tr>
                                        <td>Статус</td>
                                        <td>{getStatus(data.status)}</td>
                                    </tr>
                                    <tr>
                                        <td>Тип доставки</td>
                                        <td>{data.send_type_data.title}</td>
                                    </tr>
                                    <tr>
                                        <td>Начислено бонусов</td>
                                        <td>{data.add_bonus}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.column}>
                    <div className={classes.row}>
                        <div className={classes.cart}>
                            <div className={classes.header}>
                                <div className={classes.title}>
                                    <h2>Адрес получателя</h2>
                                </div>
                            </div>
                            <div className={classes.body}>
                                {getAddress()}
                            </div>
                        </div>
                        <div className={classes.cart}>
                            <div className={classes.header}>
                                <div className={classes.title}>
                                    <h2>Данные получателя</h2>
                                </div>
                            </div>
                            <div className={classes.body}>
                                <div className={classes.responsive}>
                                    <table className={classes.table}>
                                        <tbody className={classes.tbody}>
                                        <tr>
                                            <td>ФИО</td>
                                            <td>{data.receiver_data.full_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Телефон</td>
                                            <td>{data.receiver_data.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Комментарий</td>
                                            <td>{data.comment}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cart}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Заказ #{item.id.split('-')[item.id.split('-').length - 1]}</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <thead className={classes.thead}>
                                    <tr>
                                        <th>Продукт</th>
                                        <th style={{textAlign: 'right'}}>Артикль</th>
                                        <th style={{textAlign: 'right'}}>Количество</th>
                                        <th style={{textAlign: 'right', minWidth: '100px'}}>Базовая цена</th>
                                        <th style={{textAlign: 'right', width: '100%'}}>Итого</th>
                                    </tr>
                                    </thead>
                                    <tbody className={classes.tbody}>
                                    {data.items.map(item => (
                                        <tr>
                                            <td>
                                                <div style={{alignItems: 'center', display: 'flex'}}>
                                                    {item.product.title}
                                                </div>
                                            </td>
                                            <td style={{textAlign: 'right'}}>{item.product.article}</td>
                                            <td style={{textAlign: 'right'}}>{item.count}</td>
                                            <td style={{textAlign: 'right'}}>
                                                {item.product.discount
                                                    ? <>
                                                        <span
                                                            style={{textDecoration: 'line-through', color: '#f8285a'}}>
                                                            <span style={{color: 'black'}}>{item.product.price} ₽</span>
                                                        </span>
                                                        <span
                                                            style={{color: 'black'}}> {Math.floor(item.product.price * (100 - item.product.discount.percentage) / 100) * item.count} ₽ ({item.product.discount.percentage}%)</span>
                                                    </>
                                                    : <span>{item.product.price} ₽</span>
                                                }
                                            </td>
                                            <td style={{textAlign: 'right'}}>{item.product.discount ? Math.floor(item.product.price * (100 - item.product.discount.percentage) / 100) * item.count : item.product.price * item.count} ₽</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan={4} style={{textAlign: 'right'}}>Подсумма: </td>
                                        <td>{getSum()}₽</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: 'right'}}>Доставка: </td>
                                        <td style={{color: '#284bf8'}}>+{data.send_type_data.price}₽</td>
                                    </tr>
                                    {data.certificate_id &&
                                        <tr>
                                            <td colSpan={4} style={{textAlign: 'right'}}>Сертификат: </td>
                                            <td style={{color: '#f8285a'}}>-{data.certificate_data.type.value}₽</td>
                                        </tr>
                                    }
                                    {data.promocode_id &&
                                        <tr>
                                            <td colSpan={4} style={{textAlign: 'right'}}>Промокод</td>
                                            <td style={{
                                                textAlign: 'right',
                                                color: '#f8285a'
                                            }}>{data.promocode_data.discount}%
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td colSpan={4} style={{
                                            textAlign: 'right',
                                            fontWeight: 'bold',
                                            color: '#071437',
                                            fontSize: '1.35rem'
                                        }}>Итоговая цена:
                                        </td>
                                        <td style={{
                                            textAlign: 'right',
                                            fontWeight: 'bold',
                                            color: '#071437',
                                            fontSize: '1.35rem'
                                        }}>{data.total_amount}₽
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
