import {instance} from "./https";

export class NewsService {
    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getAll = async (search = '', limit = 10, offset = 0, journal_id ) => {
    const searchParam = search ? `q=${encodeURIComponent(search)}` : '';
    const limitParam = limit ? `limit=${limit}` : '';
    const offsetParam = offset ? `offset=${offset}` : '';
    const journalParam = journal_id ? `journalId=${journal_id}` : '';

    const queryParams = [searchParam, limitParam, offsetParam, journalParam]
        .filter(param => param)
        .join('&');

    const url = `/news?${queryParams}`;
        const response = await instance.get(url, {
            headers: {
                accept: 'application/json',
                Authorization: this.token,
            }
        });
        return response.data;
    }

    create = async (title, about, text, journalId) => {
        try {
            const response = await instance.post("/news", {
                title: title,
                about: about,
                text: text,
                journal_id: journalId,
                views: 0

            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    edit = async (id, title, about, text, journalId) => {
        try {
            const response = await instance.put(`/news/${id}`, {
                title: title,
                about: about,
                text: text,
                journal_id: journalId
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/news/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
}
