import classes from "./statusOrder.module.scss";
import React from "react";

export const getStatus = (status) => {
    const type = {
        SUCCESSED: 'Завершен',
        PENDING: 'Ожидает',
        CANCELLED: 'Отменен',
        PAIDING: 'Оплачен',
        BUILDING: 'Сборка',
        SENDING: 'В пути',
        AWAITING: 'Ожидает получателя'
    }
    return <div className={`${classes.status} 
        ${status === 'SUCCESSED' ? classes.success : ''}
        ${status === 'PENDING' ? classes.pending : ''}
        ${status === 'CANCELLED' ? classes.canceled : ''}
        ${status === 'PAIDING' ? classes.paiding : ''}
        ${status === 'BUILDING' ? classes.building : ''}
        ${status === 'SENDING' ? classes.sending : ''}
        ${status === 'AWAITING' ? classes.awaiting : ''}
        `}>{type[status]}</div>
}
