import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './CertificateTypePage.module.scss'
import {Form} from "./Form";
import {CertTypeService} from "../../service/certTypeService";
import {CertificateService} from "../../service/certificateService";
import {ClientService} from "../../service/clientService";
import {List} from "../../сomponents/list/List";
import DeleteConfirmation from "../../сomponents/confirmDelete/DeleteConfirmation";
import {Dropdown} from "../../сomponents/dropdown/dropdown";

export const CertificatePage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [certTypes, setCertTypes] = useState([]);
    const [certSelected, setCertSelected] = useState([]);
    const [clients, setClients] = useState([]);

    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const certTypeService = useMemo(() => new CertTypeService(), []);
    const certificateService = useMemo(() => new CertificateService(), []);
    const clientService = useMemo(() => new ClientService(), []);

    const getAll = useCallback(async () => {
        setLoading(true)
        const resType = await certTypeService.getAll()
        const resClient = await clientService.getAll()
        if(resClient.success)
            setClients(resClient.data)

        if(resType.success) {
            const data = resType.data.map((item) => {
                item.click = (item) => {
                    setCertSelected(item)
                }
                item.title = item.value + ' ₽'
                return item
            })
            setCertTypes(data)
        }
        const res = await certificateService.getAll(search, limit, offset, certSelected.id)

        if (res.success) {
            const certs = res.data
            certs.forEach((item) => {
                const res = resType.data.find(type => type.id === item.certificate_type_id)
                item.certificate = res
                setTotalItems(resType.pagination.totalItems);
            })
            setItems(certs)
        }

        setLoading(false)
    }, [certificateService, certTypeService, clientService, search, limit, offset, certSelected])

    useEffect(() => {
        getAll().catch().then()
    }, [getAll, search, limit, offset, certSelected]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleLimitChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
    };

    const handlePageChange = (page) => {
        setOffset(page - 1);
    };


    const handleEditClick = (item) => {
        setEditItem(item);
        setCreate(true);
    }

    const handleCancel = () => {
        setCreate(false);
        setEditItem(null);
    }

    const handleSave = async (item) => {
        if(item.id === undefined) {
            await certificateService.create(item.value, item.description)
        } else {
            await certificateService.edit(item.id, item.value, item.description)
        }
        setCreate(false);
        setEditItem(null);
        getAll()
    }

    const handleDeleteClick = (item) => {
        setDeleteItem(item);
    }

    const handleConfirmDelete = async () => {
        if (deleteItem) {
            try {
                await certificateService.delete(deleteItem.id);
                setDeleteItem(null);
                getAll();
            } catch (err) {
                alert(err)
            }
        }
    }

    const handleCancelDelete = () => {
        setDeleteItem(null);
    }

    const columns = [
        { header: "Пин-код", accessor: "code" },
        { header: "Телефон", accessor: "phone" },
        { header: "Почта", accessor: "email" },
        {
            header: "Статус",
            accessor: "activated",
            render: (item) => { return <p className={`${classes.status} ${item.activated ? classes.success : classes.canceled}`}>{item.activated ?  'Активирован' : 'Не активен'}</p> }
        },
        {
            header: "Время доставки",
            accessor: "delivery_at",
            render: (item) => {
                const date = new Date(item.delivery_at);
                return (
                    (date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate()) +
                    "." +
                    ((date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
                    "." +
                    date.getFullYear() +
                    " " +
                    date.getHours() +
                    ":" +
                    (date.getMinutes().toString().length === 1 ? "0" + date.getMinutes() : date.getMinutes())
                );
            }
        },
        {
            header: "Номинал",
            accessor: "certificate.value",
            render: (item) => `${item.certificate.value} ₽`
        }// Пустая колонка для кнопок управления
    ];

    return <div className={classes.content}>
        <h2 className={classes.title}>Сертификаты</h2>

        {!create && !deleteItem ? (
            <>
                <div className="flex flex-col md:flex-row justify-between mb-4">

                    <div className="flex space-x-2">
                        <input
                            type="text"
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="Поиск..."
                            className="px-4 py-2 rounded border border-gray-300"
                        />
                        <select
                            value={limit}
                            onChange={handleLimitChange}
                            className="px-4 py-2 rounded border border-gray-300"
                        >
                            {[10, 20, 50].map(limitOption => (
                                <option key={limitOption} value={limitOption}>{limitOption} на странице</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="grid flex-col space-y-4 md:space-y-0 md:flex-row justify-between mb-4">
                    <div className='flex items-center'>
                        <div style={{marginRight: '2.5rem'}}>Номинал</div>
                        <Dropdown items={certTypes} selected={certTypes.find(item => item.id === certSelected.id)}/>
                    </div>
                </div>
                <List columns={columns} loading={loading} items={items} handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}/>
                <div className="mt-4 flex justify-center">
                    {Array.from({length: Math.ceil(totalItems / limit)}, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            style={{
                                background: offset === index ? '#3B82F6' : '#E5E7EB',
                                color: offset === index ? 'white' : undefined
                            }}
                            className={`px-4 py-2 rounded   `}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </>
        ) : create ? (
            <Form
                item={editItem}
                onCancel={handleCancel}
                onSave={handleSave}
                types={certTypes}
                clients={clients}
            />
        ) : deleteItem && (
            <DeleteConfirmation
                title={`Вы уверены, что хотите удалить сертификат для ${deleteItem.phone} по номиналу ${deleteItem.certificate.value}₽?`}
                item={deleteItem}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
    </div>
}
