export const Certificate = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.85355 4.14645C7.94732 4.24021 8 4.36739 8 4.5V11.5C8 11.6326 7.94732 11.7598 7.85355 11.8536C7.75979 11.9473 7.63261 12 7.5 12C7.36739 12 7.24021 11.9473 7.14645 11.8536C7.05268 11.7598 7 11.6326 7 11.5V4.5C7 4.36739 7.05268 4.24021 7.14645 4.14645C7.24021 4.05268 7.36739 4 7.5 4C7.63261 4 7.75979 4.05268 7.85355 4.14645Z"
            fill="white"/>
        <path d="M11.5 5.378C12.982 3.958 16.295 6.77 11.5 10C6.705 6.77 10.018 3.957 11.5 5.378Z" fill="white"/>
        <path
            d="M2.14645 5.14645C2.24021 5.05268 2.36739 5 2.5 5H5.5C5.63261 5 5.75979 5.05268 5.85355 5.14645C5.94732 5.24021 6 5.36739 6 5.5C6 5.63261 5.94732 5.75979 5.85355 5.85355C5.75979 5.94732 5.63261 6 5.5 6H2.5C2.36739 6 2.24021 5.94732 2.14645 5.85355C2.05268 5.75979 2 5.63261 2 5.5C2 5.36739 2.05268 5.24021 2.14645 5.14645Z"
            fill="white"/>
        <path
            d="M2.14645 7.14645C2.24021 7.05268 2.36739 7 2.5 7H5.5C5.63261 7 5.75979 7.05268 5.85355 7.14645C5.94732 7.24021 6 7.36739 6 7.5C6 7.63261 5.94732 7.75979 5.85355 7.85355C5.75979 7.94732 5.63261 8 5.5 8H2.5C2.36739 8 2.24021 7.94732 2.14645 7.85355C2.05268 7.75979 2 7.63261 2 7.5C2 7.36739 2.05268 7.24021 2.14645 7.14645Z"
            fill="white"/>
        <path
            d="M2.14645 9.14645C2.24021 9.05268 2.36739 9 2.5 9H5.5C5.63261 9 5.75979 9.05268 5.85355 9.14645C5.94732 9.24021 6 9.36739 6 9.5C6 9.63261 5.94732 9.75979 5.85355 9.85355C5.75979 9.94732 5.63261 10 5.5 10H2.5C2.36739 10 2.24021 9.94732 2.14645 9.85355C2.05268 9.75979 2 9.63261 2 9.5C2 9.36739 2.05268 9.24021 2.14645 9.14645Z"
            fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.585786 2.58579C0.210714 2.96086 0 3.46957 0 4V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H14C14.5304 14 15.0391 13.7893 15.4142 13.4142C15.7893 13.0391 16 12.5304 16 12V4C16 3.46957 15.7893 2.96086 15.4142 2.58579C15.0391 2.21071 14.5304 2 14 2H2C1.46957 2 0.960859 2.21071 0.585786 2.58579ZM1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V12C15 12.2652 14.8946 12.5196 14.7071 12.7071C14.5196 12.8946 14.2652 13 14 13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V4C1 3.73478 1.10536 3.48043 1.29289 3.29289Z"
              fill="white"/>
    </svg>

}
