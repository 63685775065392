import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './CertificateTypePage.module.scss'
import {Form} from "./Form";
import {CertTypeService} from "../../service/certTypeService";
import {List} from "../../сomponents/list/List";
import DeleteConfirmation from "../../сomponents/confirmDelete/DeleteConfirmation";

export const CertificateTypePage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    const certTypeService = useMemo(() => new CertTypeService(), []);

    const getAll = useCallback(async () => {
        setLoading(true)
        const res = await certTypeService.getAll()
        console.log(res)
        if (res.success)
            setItems(res.data)
        setLoading(false)
    }, [certTypeService])

    useEffect(() => {
        getAll().then().catch()
    }, [getAll, create, editItem]);




    const handleAddClick = () => {
        setCreate(true);
        setEditItem(null);
    }

    const handleEditClick = (item) => {
        setEditItem(item);
        setCreate(true);
    }

    const handleCancel = () => {
        setCreate(false);
        setEditItem(null);
    }

    const handleSave = async (item) => {
        try {
            if(item.id === undefined) {
                await certTypeService.create(item.value, item.description)
            } else {
                await certTypeService.edit(item.id, item.value, item.description)
            }
            setCreate(false);
            setEditItem(null);
        } catch (error) {

        }
    }

    const handleDeleteClick = (item) => {
        setDeleteItem(item);
    }

    const handleConfirmDelete = async () => {
        if (deleteItem) {
            try {
                await certTypeService.delete(deleteItem.id);
                setDeleteItem(null);
                getAll();
            } catch (err) {
                alert(err)
            }
        }
    }

    const handleCancelDelete = () => {
        setDeleteItem(null);
    }

    const columns = [
        {
            header: "Номинал",
            accessor: "value",
            render: (item) => {
                return <p style={{fontWeight: 'bold'}}>{item.value} ₽</p>
            }
        },
        {header: "Описание", accessor: "description"},
    ]

    return <div className={classes.content}>
        <h2 className={classes.title}>Номиналы</h2>

        {!create && !deleteItem ? (
            <>
                <button className={classes.save} onClick={handleAddClick}>Добавить</button>
                <List columns={columns} loading={loading} items={items} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />
            </>
        ) : create ? (
            <Form
                item={editItem}
                onCancel={handleCancel}
                onSave={handleSave}
            />
        ) : deleteItem && (
            <DeleteConfirmation
                title={`Вы уверены, что хотите удалить номинал на ${deleteItem.value}?`}
                item={deleteItem}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
    </div>
}
