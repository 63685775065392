import {instance} from "./https";

export class FileService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }
    get = async (entity_type, entity_id) => {
        try {
            const response = await instance.get(`/file/${entity_type}/${entity_id}`, {
                headers: {
                    Authorization: this.token,
                    accept: 'application/json'
                },
            });
            return  response.data;
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }

    load = async (data) => {
        try {
            const response = await instance.post(`/file/upload`, data, {
                headers: {
                    Authorization: this.token,
                    'Content-Type': 'multipart/form-data',
                    accept: 'application/json'
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
    edit = async (id, position) => {
        try {
            const response = await instance.put(`/file/${id}`, {
                position: position,
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/file/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
}
