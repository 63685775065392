import React, {useCallback, useEffect, useMemo, useState} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import classes from "./Form.module.scss";
import { ImageManager } from "../../сomponents/imageManager/ImageManager";
import {JournalService} from "../../service/journalService";

export const Form = ({ item, onCancel, onSave }) => {
    const [title, setTitle] = useState(item ? item.title : '');
    const [text, setText] = useState(item ? item.text : '');
    const [about, setAbout] = useState(item ? item.about : '');
    const [journals, setJournals] = useState([])
    const [selectedJournal, setSelectedJournal] = useState(item ? item.journalId : null)

    const journalService = useMemo(() => new JournalService(), [])

    const handleSubmit = () => {
        const item_data = { id: item?.id, title, text, about, journalId: selectedJournal };
        console.log(item_data)
        onSave(item_data);
    };

    const getAll = useCallback(async () => {
        const [journalRes] = await Promise.all([
            journalService.getAll()
        ])
        if(journalRes.success)  setJournals(journalRes.data)
    }, [journalService])

    useEffect(() => {
        getAll()
    }, [getAll]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'space-between' }}>
            <div className={classes.formActions}>
                <button style={{ marginTop: '10px' }} className={classes.cancel} onClick={onCancel}>Назад</button>
            </div>
            <div className={classes.column}>
                <div className={classes.cart}>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <h2>Галерея</h2>
                        </div>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.responsive}>
                            <table className={classes.table}>
                                <tbody className={classes.tbody}>
                                <ImageManager
                                    itemImages={item ? item.images : []}
                                    entity_type={'news'}
                                    entity_id={item ? item.id : ''}
                                />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={classes.cart}>
                    <div className={classes.header}>
                        <div className={classes.title}>
                            <h2>Информация</h2>
                        </div>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.responsive}>
                            <table className={classes.table}>
                                <tbody className={classes.tbody}>
                                <tr>
                                    <td> Категория</td>
                                    <td>
                                        <select
                                            value={selectedJournal}
                                            onChange={(e) => setSelectedJournal(e.target.value)}
                                        >
                                            <option value="">Выберите категорию</option>
                                            {journals.map(cat => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.title}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Название</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            placeholder="Название"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Краткое описание</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={about}
                                            onChange={(e) => setAbout(e.target.value)}
                                            placeholder="Краткое описание"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{textAlign: 'left'}}>Текст</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <ReactQuill
                                            value={text}
                                            onChange={setText}
                                            placeholder="Текст"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button className={classes.save} onClick={handleSubmit}>Сохранить</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
