import {instance} from "./https";

export class CertificateService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getAll = async (search = '', limit = 10, offset = 0, certType ) => {
        const searchParam = search ? `q=${encodeURIComponent(search)}` : '';
        const limitParam = limit ? `limit=${limit}` : '';
        const offsetParam = offset ? `offset=${offset}` : '';
        const certTypeParam = certType ? `certificate_type_id=${certType}` : '';

        const queryParams = [searchParam, limitParam, offsetParam, certTypeParam]
            .filter(param => param)
            .join('&');

        const url = `/certificate?${queryParams}`;

        const response = await instance.get(url, {
            headers: {
                Authorization: this.token,
                accept: 'application/json'
            }
        });
        return response.data;
    }

    create = async (phone, email, accepted, delivery_at, user_id, certificate_type_id) => {
        try {
            const response = await instance.post("/certificate", {
                phone,
                email,
                accepted,
                delivery_at,
                user_id,
                certificate_type_id,
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    edit = async (id, phone, email, accepted, delivery_at, user_id, certificate_type_id) => {
        try {
            const response = await instance.put(`/certificate/${id}`, {
                phone,
                email,
                accepted,
                delivery_at,
                user_id,
                certificate_type_id,
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/certificate/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
}
