import {instance} from "./https";

export class ReviewsService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getAll = async (user_id) => {
        const response = await instance.get(`/reviews?${!!user_id ? `user_id=${user_id}` : ''}`, {
            headers: {
                Authorization: this.token,
                accept: 'application/json'
            }
        });
        return response.data;
    }

    create = async (title) => {
        try {
            const response = await instance.post("/reviews", {
                title: title,
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    edit = async (id, title) => {
        try {
            const response = await instance.put(`/reviews/${id}`, {
                title: title,
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/reviews/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
}
