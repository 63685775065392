export const PackageList = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_9160)">
            <path
                d="M2.95 0.4C3.04315 0.275804 3.16393 0.175001 3.30279 0.105573C3.44164 0.0361451 3.59475 0 3.75 0L12.25 0C12.4052 0 12.5584 0.0361451 12.6972 0.105573C12.8361 0.175001 12.9569 0.275804 13.05 0.4L15.9 4.2C15.9649 4.28655 16 4.39181 16 4.5V15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15V4.5C0 4.39181 0.0350889 4.28655 0.1 4.2L2.95 0.4ZM7.5 1H3.75L1.5 4H7.5V1ZM8.5 1V4H14.5L12.25 1H8.5ZM15 5H1V15H15V5Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_2_9160">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}
