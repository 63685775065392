import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './ShopPage.module.scss'
import {Form} from "./Form";
import {ShopService} from "../../service/shopService";
import {List} from "../../сomponents/list/List";
import DeleteConfirmation from "../../сomponents/confirmDelete/DeleteConfirmation";

export const ShopPage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [errors, setErrors] = useState([])

    const shopService = useMemo(() => new ShopService(), []);

    const getAll = useCallback(async () => {
        setLoading(true);
        const res = await shopService.getAll(search, limit, offset);
        console.log(res);
        if (res.success) {
            setItems(res.data);
            setTotalItems(res.pagination.totalItems);
        }
        setLoading(false);
    }, [shopService, search, limit, offset]);

    useEffect(() => {
        getAll().then().catch();
    }, [getAll, search, limit, offset, create, editItem]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleLimitChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
    };

    const handlePageChange = (page) => {
        setOffset(page - 1);
    };


    const handleAddClick = () => {
        setCreate(true);
        setEditItem(null);
    }

    const handleEditClick = (item) => {
        setEditItem(item);
        setCreate(true);
    }

    const handleCancel = () => {
        setCreate(false);
        setEditItem(null);
    }

    const handleSave = async (item) => {
        try {
            if(item.id === undefined) {
                await shopService.create(item)
            } else {
                await shopService.edit(item.id, item)
            }
            setTimeout(() => {
                setCreate(false);
                setEditItem(null);
            }, [1000])
        } catch (err) {
            const errors = JSON.parse(err.message)
            setErrors(errors);
            console.log('Errors: ', errors);
        }
    }

    const handleDeleteClick = (item) => {
        setDeleteItem(item);
    }

    const handleConfirmDelete = async () => {
        if (deleteItem) {
            try {
                await shopService.delete(deleteItem.id);
                setDeleteItem(null);
                getAll();
            } catch (err) {
                alert(err)
            }

        }
    }

    const handleCancelDelete = () => {
        setDeleteItem(null);
    }

    const columns = [
        {header: 'Название', accessor: "title", render: (item) => {
                return <p style={{fontWeight: 'bold'}}>{item.title}</p>
            }
        },
        {header: 'Адрес', accessor: 'address'},
        {header: 'Почта', accessor: 'email'},
        {header: 'Телефоны', accessor: 'phones', render: item => {
            return item.phones.map(item => <>{item}<br/></>)
            }},
        {header: 'Время работы', accessor: 'times', render: item => {
                return <>
                    Понедельник: {item.times[0]}<br/>
                    Вторник: {item.times[1]}<br/>
                    Среда: {item.times[2]}<br/>
                    Четверг: {item.times[3]}<br/>
                    Пятница: {item.times[4]}<br/>
                    Суббота: {item.times[5]}<br/>
                    Воскресенье: {item.times[6]}
                </>
            }
        },
    ]

    return <div className={classes.content}>
        <h2 className={classes.title}>Магазины</h2>

        {!create && !deleteItem ? (
            <>
                <div className="flex flex-col md:flex-row justify-between mb-4">
                    <div className="flex space-x-2">
                        <button className={classes.save} onClick={handleAddClick} style={{marginBottom: '20px'}}>Добавить</button>
                    </div>
                    <div className="flex space-x-2">
                        <input
                            type="text"
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="Поиск..."
                            className="px-4 py-2 rounded border border-gray-300"
                        />
                        <select
                            value={limit}
                            onChange={handleLimitChange}
                            className="px-4 py-2 rounded border border-gray-300"
                        >
                            {[10, 20, 50].map(limitOption => (
                                <option key={limitOption} value={limitOption}>{limitOption} на странице</option>
                            ))}
                        </select>
                    </div>
                </div>
                <List columns={columns} loading={loading} handleDeleteClick={handleDeleteClick}
                      handleEditClick={handleEditClick}
                      items={items}/>
                <div className="mt-4 flex justify-center">
                    {Array.from({length: Math.ceil(totalItems / limit)}, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            style={{
                                background: offset === index ? '#3B82F6' : '#E5E7EB',
                                color: offset === index ? 'white' : undefined
                            }}
                            className={`px-4 py-2 rounded   `}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </>
        ) : create ? (
            <Form
                item={editItem}
                onCancel={handleCancel}
                onSave={handleSave}
                errors={errors}
                setErrors={setErrors}
            />
        ) : deleteItem && (
            <DeleteConfirmation
                title={`Вы уверены, что хотите удалить ${deleteItem.title}?`}
                item={deleteItem}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
    </div>
}
