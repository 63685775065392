import {instance} from "./https";

export class DiscountService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getAll = async () => {
        const response = await instance.get('/discounts', {
            headers: {
                Authorization: this.token,
                accept: 'application/json'
            }
        });
        return response.data;
    }

    create = async ({productId, percentage, startDate, endDate, active}) => {
        try {
            const response = await instance.post("/discounts", {
                product_id: productId,
                percentage: percentage,
                start_date: new Date(startDate).toISOString(),
                end_date: new Date(endDate).toISOString(),
                activated: active
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    edit = async ({id, productId, percentage, startDate, endDate, active}) => {
        try {
            const response = await instance.put(`/discounts/${id}`, {
                product_id: productId,
                percentage: percentage,
                start_date: new Date(startDate).toISOString(),
                end_date: new Date(endDate).toISOString(),
                activated: active
            }, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            const data = error.response
            if(data.status === 400) {
                const result = data.data
                console.log('444444444444', result)
                throw new Error(JSON.stringify(result.message))
            }
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/discounts/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
}
