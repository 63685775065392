import React, {useContext} from "react";
import {AuthContext} from "../../context/AuthContext";

export const Sidebar = ({onActive, setOnActive, items = [], setSelected}) => {
    const {logout} = useContext(AuthContext)

    return <aside
        className={`w-full sm:w-[250px] fixed top-0 left-0 z-40 h-screen transition-transform sm:translate-x-0 ${onActive ? '' : '-translate-x-full'}`}>
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
            <ul className="space-y-2 font-medium">
                <li className="w-[100px] sm:block md:hidden">
                    <button onClick={() => setOnActive(false)}
                            className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                        <span className="flex-1 ms-3 whitespace-nowrap ml-[10px]">Закрыть</span>
                    </button>
                </li>
                {items.map((item, index) => {
                    const open = () => {
                        const res = document.getElementById('submenu-' + index)
                        res.style.display = res.style.display === 'block' ? 'none' : 'block'
                    }

                    return <li key={index}>
                        <button className={`w-full flex flex-row justify-between rounded-lg items-center px-[10px] ${item?.items ? '' : 'dark:hover:bg-gray-700'}`} onClick={() => {
                            if(item?.items) {
                                open()
                            } else {
                                setSelected(item); setOnActive(false) }}}
                        >
                            <div className={`flex items-center p-2 text-gray-900 dark:text-white group`}>
                                <div className={item.icon ? 'mr-[10px]' : ''}>{item.icon}</div>
                                <span>{item.label}</span>
                            </div>
                            {item?.items && <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                 viewBox="0 0 10 6">
                                <path stroke="white" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="m1 1 4 4 4-4"/>
                            </svg>}
                        </button>

                        {item?.items &&
                            <ul id={`submenu-${index}`} className="hidden py-2 space-y-2">
                                {item?.items?.map((sub, index) =>
                                    <li key={index}>
                                        <button className="w-full flex flex-row justify-between items-center" onClick={() => { setSelected(sub); setOnActive(false) }}>
                                            <div
                                                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                                                {sub.icon}
                                                <span className="ms-3 ml-[10px]">{sub.label}</span>
                                            </div>
                                        </button>
                                    </li>
                                )}
                            </ul>
                        }
                    </li>
                })}
                <li>
                    <button  className="w-full flex flex-row justify-between  dark:hover:bg-gray-700 rounded-lg items-center px-[10px]" onClick={() => { logout(); setOnActive(false) }}>

                        <div className="flex items-center p-2 text-gray-900 dark:text-white group">
                            <svg
                                className="mr-[10px] flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 18 16">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"/>
                            </svg>
                            <span className="ms-3">Выход</span>
        </div>

    </button>
                </li>
            </ul>
        </div>
    </aside>
}

