import React from "react";
import { Dropdown } from "../dropdown/dropdown";
import classes from './List.module.scss'

export const List = ({ columns, items = [], loading, handleEditClick, handleDeleteClick, hiddenAction = false }) => {
    if (loading) return <div className={classes.loader}></div>;

    if (!Array.isArray(items)) {
        console.error("Expected 'items' to be an array, but got:", typeof items);
        return <div>Error: Invalid data</div>;
    }

    return (
        <table className="w-full block md:table md:table-auto overflow-x-auto">
            <thead>
            <tr className="bg-gray-100 text-gray-800 text-left">
                {columns.map((column, index) => (
                    <th
                        key={index}
                        className="px-4 py-2"
                        style={{ width: column.width ? column.width : 'auto' }}
                    >
                        {column.header}
                    </th>
                ))}
                {!hiddenAction && (
                    <th className="px-4 py-2" style={{ width: '150px' }}>
                        Управление
                    </th>
                )}
            </tr>
            </thead>
            <tbody>
            {items.map((item) => (
                <tr
                    className="border-b hover:bg-gray-50"
                    key={item.id}
                >
                    {columns.map((column, index) => (
                        <td key={index} className="px-4 py-2">
                            {column.render ? column.render(item) : item[column.accessor]}
                        </td>
                    ))}
                    {!hiddenAction && (
                        <td className="px-4 py-2 text-center">
                            <Dropdown items={[
                                {
                                    click: () => handleEditClick(item),
                                    title: 'Просмотр'
                                },
                                {
                                    click: () => handleDeleteClick(item),
                                    title: 'Удалить'
                                }
                            ]}/>
                        </td>
                    )}
                </tr>
            ))}
            {items.length === 0 && (
                <tr>
                    <td colSpan={columns.length + (hiddenAction ? 0 : 1)} className="text-center py-4">
                        Список пуст
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );
};
