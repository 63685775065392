import React, {useState, useEffect, useMemo, useCallback} from "react";
import classes from './PromoPage.module.scss'
import { FileService } from "../../service/fileService";

export const PromoPage = () => {
    const [slides, setSlides] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileToPreview, setFileToPreview] = useState(null);
    const [loading, setLoading] = useState(false); // Состояние для загрузки
    const fileService = useMemo(() => new FileService(), []);

    const getAll = useCallback(async () => {
        try {
            const response = await fileService.get('main_banner', '0');
            const groupedSlides = groupFilesByPosition(response.data);
            setSlides(groupedSlides);
        } catch (error) {
            console.error('Ошибка при загрузке файлов:', error);
        }
    }, [fileService]);

    useEffect(() => {
        getAll().then().catch()
    }, [getAll]);



    const groupFilesByPosition = (files) => {
        const slidesMap = {};
        files.forEach(file => {
            if (!slidesMap[file.position]) {
                slidesMap[file.position] = [];
            }
            slidesMap[file.position].push(file);
        });
        return Object.keys(slidesMap).map(position => ({
            position: Number(position),
            files: slidesMap[position]
        }));
    };

    const handleFileChange = (event, slidePosition) => {
        const file = event.target.files[0];
        setSelectedFile({ file, slidePosition });
        setFileToPreview(URL.createObjectURL(file)); // Для предварительного просмотра
    };

    const handleFileUpload = async () => {
        if (selectedFile) {
            setLoading(true); // Показать индикатор загрузки
            const formData = new FormData();
            formData.append('file', selectedFile.file);
            formData.append('entity_type', 'main_banner');
            formData.append('entity_id', '0');
            formData.append('position', selectedFile.slidePosition);

            try {
                await fileService.load(formData);
                setSelectedFile(null);
                setFileToPreview(null);
                getAll()
            } catch (error) {
                console.error('Ошибка при загрузке файла:', error);
            } finally {
                setLoading(false); // Скрыть индикатор загрузки
            }
        }
    };

    const handleDeleteFile = async (fileId) => {
        try {
            await fileService.delete(fileId);
            getAll()
        } catch (error) {
            console.error('Ошибка при удалении файла:', error);
        }
    };

    const handleCancelPreview = () => {
        setSelectedFile(null);
        setFileToPreview(null);
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Раздел с промо</h2>
            {slides.map((slide, slideIndex) => (
                <div key={slide.position} className="mb-4 border p-4 rounded">
                    <h3 className="font-bold">Слайд {slide.position}</h3>
                    <div className="flex">
                        {slide.files.map((file, fileIndex) => (
                            <div key={file.id} className="relative mr-4">
                                {file.type_file === "video" ? (
                                    <video
                                        autoPlay
                                        muted
                                        src={"https://api.uzorutum.ru/public" + file.path}
                                        className="w-32 h-32"
                                    />
                                ) : (
                                    <img
                                        src={"https://api.uzorutum.ru/public" + file.path}
                                        alt={`File ${fileIndex + 1}`}
                                        className="w-32 h-32"
                                    />
                                )}
                                <button
                                    className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
                                    onClick={() => handleDeleteFile(file.id)}
                                >
                                    Удалить
                                </button>
                            </div>
                        ))}
                        {slide.files.length < 2 && (
                            <div className="w-32 h-32 bg-gray-100 flex items-center justify-center">
                                <label htmlFor="files" className="w-full text-center">Выбрать файл</label>
                                <input id="files" type="file" className="hidden" onChange={(e) => handleFileChange(e, slide.position)} />
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <button
                onClick={() => setSlides([...slides, { position: slides.length + 1, files: [] }])}
                className="px-4 py-2 bg-blue-500 text-white rounded"
            >
                Добавить новый слайд
            </button>

            {loading && (
                <div className="mt-4 flex items-center justify-center">
                    <div className={classes.loader}></div> {/* CSS для индикатора загрузки */}
                </div>
            )}

            {fileToPreview && !loading && (
                <div className="mt-4">
                    <h3 className="font-bold">Предпросмотр файла</h3>
                    {selectedFile.file.type.startsWith('video') ? (
                        <video src={fileToPreview} controls className="w-64 h-64" />
                    ) : (
                        <img src={fileToPreview} alt="Предпросмотр" className="w-32 h-32" />
                    )}
                    <div className="flex mt-2">
                        <button
                            onClick={handleFileUpload}
                            className="px-4 py-2 bg-green-500 text-white rounded mr-2"
                        >
                            Подтвердить и загрузить
                        </button>
                        <button
                            onClick={handleCancelPreview}
                            className="px-4 py-2 bg-red-500 text-white rounded"
                        >
                            Отменить
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
