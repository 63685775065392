import React, { createContext, useState, useEffect } from "react";
import {AuthService} from "../service/authService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false);
    const [user, setUser] = useState({});
    const [loadingAuth, setLoadingAuth] = useState(true);

    const checkToken = async () => {
        setLoadingAuth(true)
        const token = localStorage.getItem('token')
        const authService = new AuthService(token)
        const result = await authService.checkAuth()
        if(result) {
            setIsAuth(true)
        } else {
            localStorage.removeItem('token')
            setIsAuth(false)
        }
        setTimeout(() => {
            setLoadingAuth(false)
        }, [1000])
    }

    const logout = () => {
        localStorage.removeItem('token')
        setIsAuth(false)
    }

    useEffect(() => {
        checkToken()
    }, []);

    return (
        <AuthContext.Provider value={{ isAuth, setIsAuth, logout, setUser, user, loadingAuth }}>
            {children}
        </AuthContext.Provider>
    );
};
