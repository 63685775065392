import {instance} from "./https";
export class AuthService {
    token = ''

    constructor(token) {
        this.token = token ? token : localStorage.getItem('token')
    }

    checkAuth = async () => {
        try {
            const response = await instance.get('/auth/check', {
                headers: {
                    Authorization: this.token,
                    accept: 'application/json'
                }
            });

            const { success, data } = response.data;

            if (success && data.role === "admin") {
                return true
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    };
}
