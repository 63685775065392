import React, {useState, useRef, useEffect, useMemo, useCallback} from "react";
import { MapContainer, TileLayer, Polygon, FeatureGroup, Tooltip } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { DeliveryZoneService } from "../../service/deliveryZoneService";
import classes from './DeliveryZon.module.scss'

const DeliveryZonesMap = () => {
    const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);
    const [formData, setFormData] = useState({ name: "", description: "", price: 0 });
    const [drawing, setDrawing] = useState(false);
    const [activePolygonLayer, setActivePolygonLayer] = useState(null);
    const featureGroupRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [accepted, setAccepted] = useState(false)

    const deliveryZoneService = useMemo(() => new DeliveryZoneService(), [])

    const getAll = useCallback(async () => {
        setLoading(true);
        const res = await deliveryZoneService.getAll();
        console.log(res);
        if (res.success) {
            setZones(res.data);
        }
        setTimeout(() => {
            setLoading(false);
        }, [1000])
    }, [deliveryZoneService])

    useEffect(() => {
        getAll().then().catch()
    }, [getAll]);

    const onCreated = (e) => {
        const { layer } = e;
        setActivePolygonLayer(layer);
        setDrawing(false);
    };

    const onAccepted = async () => {
        setAccepted(true)
    };

    const save = async () => {

        const updatedZone = {
            ...selectedZone,
            polygon: activePolygonLayer.getLatLngs()[0].map(latlng => [latlng.lng, latlng.lat]),
            title: formData.name,
            description: formData.description,
            price: formData.price,
        };
        if (selectedZone && selectedZone?.id) {
            await deliveryZoneService.edit(selectedZone.id, updatedZone);
            setZones(zones.map(zone => zone.id === selectedZone.id ? updatedZone : zone));
        } else {
            await deliveryZoneService.create(updatedZone);
            setZones([...zones, updatedZone]);
        }
        setActivePolygonLayer(null);
        setSelectedZone(null);
        setFormData({ name: "", description: "", price: 0 });
        setAccepted(false)
    };

    const onDeleted = async (item) => {
        await deliveryZoneService.delete(item.id);
        setZones(zones.filter(zone => zone.id !== item.id));
        setActivePolygonLayer(null);
        setSelectedZone(null);
        setFormData({ name: "", description: "", price: 0 });
    };

    const startDrawing = () => {
        setDrawing(true);
        setSelectedZone(null);
        setFormData({ name: "", description: "", price: 0 });
    };

    const cancelDrawing = () => {
        setDrawing(false);
        setActivePolygonLayer(null); // Очистка активного полигона
        setFormData({ name: "", description: "", price: 0 });
        setSelectedZone(null)
        setAccepted(false)
    };

    const handleZoneClick = (zone) => {
        setSelectedZone(zone);
        setFormData({
            name: zone.title,
            description: zone.description,
            price: zone.price,
        });
        setAccepted(true)
    };

    if(loading) return <div className={classes.loader}></div>

    return (
        <div className="flex sm:h-screen w-full justify-center flex-col sm:flex-row">
            <div className="w-full pb-2 sm:w-1/3 sm:p-4">
                <h2 className="text-xl mb-4">Зоны доставки</h2>
                <ul className="mb-4">
                    {zones.map(zone => (
                        <li key={zone.id} className="mb-2">
                            <div className="block rounded-lg p-6 text-surface shadow-secondary-1 outline outline-1">
                                <h3 className="mb-2 text-xl font-medium leading-tight">{zone.title}</h3>
                                <p className="mb-4 text-base">{zone.description}</p>
                                <p className="text-sm font-bold">Стоимость: {zone.price} ₽</p>
                                <button
                                    onClick={() => handleZoneClick(zone)}
                                    type="button"
                                    className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong bg-[#f4f4f4]"
                                >
                                    Редактировать
                                </button>
                                <button
                                    onClick={() => {
                                        onDeleted(zone)
                                    }}
                                    type="button"
                                    className="inline-block rounded bg-red-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-primary-3 transition duration-150 ease-in-out hover:bg-red-400 hover:shadow-primary-2 focus:bg-red-400 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-red-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong bg-[#f4f4f4] ml-2"
                                >
                                    Удалить
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
                <button
                    onClick={drawing ? cancelDrawing : startDrawing}
                    className="w-full bg-blue-500 text-white p-2 rounded"
                >
                    {drawing ? "Отмена" : 'Создать зону'}
                </button>

                {activePolygonLayer  && (
                    <div className="mt-4">
                        <button
                            onClick={onAccepted}
                            className="w-full bg-green-500 text-white p-2 rounded mb-2"
                        >
                            Принять полигон
                        </button>
                        <button
                            onClick={onDeleted}
                            className="w-full bg-red-500 text-white p-2 rounded"
                        >
                            Удалить полигон
                        </button>
                    </div>
                )}

                {(selectedZone || accepted) && (
                    <div className="mt-4">
                        <h3 className="text-lg mb-2">Детали зоны</h3>
                        <div className="mb-2">
                            <label className="block mb-1">Название</label>
                            <input
                                type="text"
                                value={formData.name}
                                onChange={(e) => setFormData({...formData, name: e.target.value})}
                                className="w-full p-2 border border-gray-300 rounded"
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block mb-1">Описание</label>
                            <input
                                type="text"
                                value={formData.description}
                                onChange={(e) => setFormData({...formData, description: e.target.value})}
                                className="w-full p-2 border border-gray-300 rounded"
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block mb-1">Цена</label>
                            <input
                                type="number"
                                value={formData.price}
                                onChange={(e) => setFormData({...formData, price: e.target.value})}
                                className="w-full p-2 border border-gray-300 rounded"
                            />
                        </div>
                        <button
                            onClick={save}
                            className="w-full bg-green-500 text-white p-2 rounded"
                        >
                            Сохранить зону
                        </button>
                        <button
                            onClick={cancelDrawing}
                            className="w-full bg-gray-400 text-black p-2 rounded mt-[10px]"
                        >
                            Отмена
                        </button>
                    </div>
                )}
            </div>

            <div className="w-full h-[200px] sm:w-2/3 sm:h-1/2">
                <MapContainer center={[62.0339, 129.733]} zoom={13} style={{height: "100%", width: "100%"}}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <FeatureGroup ref={featureGroupRef}>
                        {drawing && (
                            <EditControl
                                position="topright"
                                onCreated={onCreated}
                                draw={{
                                    rectangle: false,
                                    circle: false,
                                    marker: false,
                                    circlemarker: false,
                                }}
                                featureGroup={featureGroupRef.current}
                            />
                        )}
                        {zones.map((zone, index) => (
                            <Polygon key={index} positions={zone.polygon.map(([lng, lat]) => [lat, lng])}>
                                <Tooltip direction="center" permanent>
                                    <div>
                                        <h3 className="font-bold">{zone.title}</h3>
                                        <p>{zone.description}</p>
                                        <p>Цена: {zone.price} ₽</p>
                                    </div>
                                </Tooltip>
                            </Polygon>
                        ))}
                    </FeatureGroup>
                </MapContainer>
            </div>
        </div>
    );
};

export default DeliveryZonesMap;
