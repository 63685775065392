import {instance} from "./https";

export class StatsService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }

    getOrderCount = async () => {
        const response = await instance.get('/stats/ordersAll', {
            headers: {
                Authorization: this.token,
                accept: 'application/json'
            }
        });
        return response.data;
    }
}
