export const Proba = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_7746)">
            <path
                d="M3.1 0.7C3.14657 0.637902 3.20697 0.5875 3.27639 0.552786C3.34582 0.518073 3.42238 0.5 3.5 0.5H12.5C12.5776 0.5 12.6542 0.518073 12.7236 0.552786C12.793 0.5875 12.8534 0.637902 12.9 0.7L15.876 4.674C16.025 4.859 16.032 5.124 15.886 5.318L8.4 15.3C8.35343 15.3621 8.29303 15.4125 8.22361 15.4472C8.15418 15.4819 8.07762 15.5 8 15.5C7.92238 15.5 7.84582 15.4819 7.77639 15.4472C7.70697 15.4125 7.64657 15.3621 7.6 15.3L0.1 5.3C0.0350889 5.21345 0 5.10819 0 5C0 4.89181 0.0350889 4.78655 0.1 4.7L3.1 0.7ZM14.486 4.485L12.68 2.075L11.904 4.488L14.486 4.485ZM10.853 4.489L11.814 1.5H4.186L5.149 4.495L10.853 4.489ZM5.47 5.495L8 13.366L10.532 5.49L5.47 5.495ZM4.099 4.496L3.319 2.074L1.501 4.499L4.099 4.496ZM1.499 5.5L6.612 12.317L4.42 5.497L1.5 5.5H1.499ZM9.388 12.317L14.511 5.487L11.583 5.489L9.388 12.317Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_2_7746">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}
