import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './OrderPage.module.scss'
import {Form} from "./Form";
import {OrderService} from "../../service/orderService";
import {getStatus} from "../../сomponents/support/getStatus";
import {List} from "../../сomponents/list/List";
import DeleteConfirmation from "../../сomponents/confirmDelete/DeleteConfirmation";

export const OrderPage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);

    const orderService = useMemo(() => new OrderService(), [])

    const getAll = useCallback(async () => {
        setLoading(true)
        const res = await orderService.getAll(undefined, undefined, search, undefined, limit, offset)
        console.log(res)
        if (res.success){
            setItems(res.data)
            setTotalItems(res.pagination.totalItems);
        }
        setLoading(false)
    }, [orderService, search, limit, offset])


    useEffect(() => {
        getAll().then().catch()
    }, [getAll, search, limit, offset]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleLimitChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
    };

    const handlePageChange = (page) => {
        setOffset(page - 1);
    };

    const handleEditClick = (item) => {
        setEditItem(item);
        console.log(item)
        setCreate(true);
    }

    const handleCancel = () => {
        setCreate(false);
        setEditItem(null);
    }

    const handleSave = async (item) => {
        console.log(item)
        if(item.id === undefined) {
            await orderService.create(item)
        } else {
            await orderService.edit(item.id, item)
        }
        setCreate(false);
        setEditItem(null);
        getAll()
    }

    const handleDeleteClick = (item) => {
        if(item.length > 0) {
            handleConfirmDelete(selectedItems)
        } else {
            setDeleteItem(item);
        }
    }

    const handleConfirmDelete = async (items) => {
        if (deleteItem) {
            await orderService.delete(deleteItem.id);
            setDeleteItem(null);
        } else {
            items.map(async (id) => {
                await orderService.delete(id);
            })
        }
        getAll();
    }

    const handleCancelDelete = () => {
        setDeleteItem(null);
    }

    const columns = [
        {header: 'Номер заказа', accessor: 'id', render: (item) => {
                const idArray = item.id.split('-')
                return <p style={{marginLeft: '2.5rem', fontWeight: 'bold'}}>{idArray[idArray.length - 1]}</p>
            }
        },
        {
            header: 'Клиент', render: (item) => {
                return item.first_name + " " + item.last_name
            }
        },
        {header: 'Статус', accessor: 'status', render: (item) => {
            return getStatus(item.status)
            }},
        {header: 'Сумма', accessor: 'total_amount', render: (item) => {
            return item.total_amount + "₽"
            }},
        {header: 'Дата оформления', accessor: 'created_at', render: (item) => {
            return new Date(item.created_at).toLocaleDateString() + " " + new Date(item.created_at).toLocaleTimeString()
            }},
        {header: 'Дата изменения', accessor: 'updated_at', render: (item) => {
                return new Date(item.updated_at).toLocaleDateString() + " " + new Date(item.updated_at).toLocaleTimeString()
            }}
    ]

    return <div className={classes.content}>
        <h2 className={classes.title}>Заказы</h2>

        {!create && !deleteItem
            ? <>
                <div className="flex flex-col md:flex-row justify-between mb-4">
                    <div className="flex space-x-2">
                        {selectedItems.length > 0
                            && <button className={classes.save} onClick={() => handleDeleteClick(selectedItems)}>Удалить</button>}
                    </div>
                    <div className="flex space-x-2">
                        <input
                            type="text"
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="Поиск..."
                            className="px-4 py-2 rounded border border-gray-300"
                        />
                        <select
                            value={limit}
                            onChange={handleLimitChange}
                            className="px-4 py-2 rounded border border-gray-300"
                        >
                            {[10, 20, 50].map(limitOption => (
                                <option key={limitOption} value={limitOption}>{limitOption} на странице</option>
                            ))}
                        </select>
                    </div>
                </div>
                <List
                    columns={columns}
                    loading={loading}
                    handleDeleteClick={handleDeleteClick}
                    handleEditClick={handleEditClick}
                    onSelectChange={setSelectedItems}
                    items={items}/>
                <div className="mt-4 flex justify-center">
                    {Array.from({length: Math.ceil(totalItems / limit)}, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            style={{
                                background: offset === index ? '#3B82F6' : '#E5E7EB',
                                color: offset === index ? 'white' : undefined
                            }}
                            className={`px-4 py-2 rounded   `}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </>
            : create ? <Form item={editItem} onCancel={handleCancel} onSave={handleSave}/>
                : deleteItem &&
                <DeleteConfirmation title={`Вы уверены, что хотите удалить ${deleteItem.title}?`} item={deleteItem}
                                    onConfirm={handleConfirmDelete} onCancel={handleCancelDelete}/>}
    </div>
}




