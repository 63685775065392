export const Promocode = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.14645 5.14645C4.05268 5.24021 4 5.36739 4 5.5C4 5.63261 4.05268 5.75979 4.14645 5.85355C4.24021 5.94732 4.36739 6 4.5 6H11.5C11.6326 6 11.7598 5.94732 11.8536 5.85355C11.9473 5.75979 12 5.63261 12 5.5C12 5.36739 11.9473 5.24021 11.8536 5.14645C11.7598 5.05268 11.6326 5 11.5 5H4.5C4.36739 5 4.24021 5.05268 4.14645 5.14645Z"
            fill="white"/>
        <path
            d="M4.14645 10.1464C4.05268 10.2402 4 10.3674 4 10.5C4 10.6326 4.05268 10.7598 4.14645 10.8536C4.24021 10.9473 4.36739 11 4.5 11H11.5C11.6326 11 11.7598 10.9473 11.8536 10.8536C11.9473 10.7598 12 10.6326 12 10.5C12 10.3674 11.9473 10.2402 11.8536 10.1464C11.7598 10.0527 11.6326 10 11.5 10H4.5C4.36739 10 4.24021 10.0527 4.14645 10.1464Z"
            fill="white"/>
        <path
            d="M4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11C11.2652 7 11.5196 7.10536 11.7071 7.29289C11.8946 7.48043 12 7.73478 12 8C12 8.26522 11.8946 8.51957 11.7071 8.70711C11.5196 8.89464 11.2652 9 11 9H5C4.73478 9 4.48043 8.89464 4.29289 8.70711C4.10536 8.51957 4 8.26522 4 8C4 7.73478 4.10536 7.48043 4.29289 7.29289Z"
            fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.43934 3.43934C0.158035 3.72064 0 4.10218 0 4.5V6C0 6.13261 0.0526784 6.25979 0.146447 6.35355C0.240215 6.44732 0.367392 6.5 0.5 6.5C0.897825 6.5 1.27936 6.65804 1.56066 6.93934C1.84196 7.22064 2 7.60218 2 8C2 8.39782 1.84196 8.77936 1.56066 9.06066C1.27936 9.34196 0.897825 9.5 0.5 9.5C0.367392 9.5 0.240215 9.55268 0.146447 9.64645C0.0526784 9.74021 0 9.86739 0 10V11.5C0 11.8978 0.158035 12.2794 0.43934 12.5607C0.720644 12.842 1.10218 13 1.5 13H14.5C14.8978 13 15.2794 12.842 15.5607 12.5607C15.842 12.2794 16 11.8978 16 11.5V10C16 9.86739 15.9473 9.74021 15.8536 9.64645C15.7598 9.55268 15.6326 9.5 15.5 9.5C15.1022 9.5 14.7206 9.34196 14.4393 9.06066C14.158 8.77936 14 8.39782 14 8C14 7.60218 14.158 7.22064 14.4393 6.93934C14.7206 6.65804 15.1022 6.5 15.5 6.5C15.6326 6.5 15.7598 6.44732 15.8536 6.35355C15.9473 6.25979 16 6.13261 16 6V4.5C16 4.10218 15.842 3.72064 15.5607 3.43934C15.2794 3.15804 14.8978 3 14.5 3H1.5C1.10218 3 0.720644 3.15804 0.43934 3.43934ZM1.14645 4.14645C1.24021 4.05268 1.36739 4 1.5 4H14.5C14.6326 4 14.7598 4.05268 14.8536 4.14645C14.9473 4.24021 15 4.36739 15 4.5V5.55C14.4349 5.66476 13.9268 5.97136 13.5618 6.41787C13.1969 6.86438 12.9975 7.42332 12.9975 8C12.9975 8.57668 13.1969 9.13562 13.5618 9.58213C13.9268 10.0286 14.4349 10.3352 15 10.45V11.5C15 11.6326 14.9473 11.7598 14.8536 11.8536C14.7598 11.9473 14.6326 12 14.5 12H1.5C1.36739 12 1.24021 11.9473 1.14645 11.8536C1.05268 11.7598 1 11.6326 1 11.5V10.45C1.56514 10.3352 2.07324 10.0286 2.43819 9.58213C2.80314 9.13562 3.00251 8.57668 3.00251 8C3.00251 7.42332 2.80314 6.86438 2.43819 6.41787C2.07324 5.97136 1.56514 5.66476 1 5.55V4.5C1 4.36739 1.05268 4.24021 1.14645 4.14645Z"
              fill="white"/>
    </svg>

}
