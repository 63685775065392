import classes from './HomePage.module.scss'
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from "react";
import {StatsService} from "../../service/statsService";
import {getStatus} from "../../сomponents/support/getStatus";
import {List} from "../../сomponents/list/List";
import {OrderService} from "../../service/orderService";

export const HomePage = () => {
    const [countOrder, setCountOrder] = useState(0)
    const [countProduct, setCountProduct] = useState(0)
    const [priceTotal, setPriceTotal] = useState(0)
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(true)

    const orderService = useMemo(() => new OrderService(), [])
    const statsService = useMemo(() => new StatsService(), [])

    const statsLoad = useCallback(async () => {
        setLoading(true)
        const [dataStats, dataOrders] = await Promise.all([
            await statsService.getOrderCount(),
            await orderService.getAll(undefined, undefined, undefined, undefined, undefined, undefined, )
        ])
        console.log(dataStats, dataOrders)
        if(dataStats.success) {
            setCountOrder(dataStats.data.count_order)
            setCountProduct(dataStats.data.count_product)
            setPriceTotal(dataStats.data.price_total)
        }
        if(dataOrders.success) {
            setOrders(dataOrders.data)
            console.log(dataOrders)
        }
        setLoading(false)
    }, [orderService, statsService])


    useEffect(() => {
        statsLoad().then().catch()
    }, [statsLoad]);



    const columns = [
        {header: 'Номер заказа', accessor: 'id', render: (item) => {
                const idArray = item.id.split('-')
                return <p style={{marginLeft: '2.5rem', fontWeight: 'bold'}}>{idArray[idArray.length - 1]}</p>
            }
        },
        {
            header: 'Клиент', render: (item) => {
                return item.first_name + " " + item.last_name
            }
        },
        {header: 'Статус', accessor: 'status', render: (item) => {
                return getStatus(item.status)
            }},
        {header: 'Сумма', accessor: 'total_amount', render: (item) => {
                return item.total_amount + "₽"
            }},
        {header: 'Дата оформления', accessor: 'created_at', render: (item) => {
                return new Date(item.created_at).toLocaleDateString() + " " + new Date(item.created_at).toLocaleTimeString()
            }},
        {header: 'Дата изменения', accessor: 'updated_at', render: (item) => {
                return new Date(item.updated_at).toLocaleDateString() + " " + new Date(item.updated_at).toLocaleTimeString()
            }}
    ]
    if(loading) return <div className={classes.loader}></div>

    return <div className={classes.content}>
        <h2 className={classes.title}>Главная</h2>


        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
            <div className="bg-white p-4 rounded-lg shadow">
                <h2 className="text-lg font-semibold mb-2">Общее количество заказов</h2>
                <p className="text-2xl font-bold">{countOrder}</p>
                <p className="text-sm text-gray-500">Всего заказов за всё время</p>
            </div>


            <div className="bg-white p-4 rounded-lg shadow">
                <h2 className="text-lg font-semibold mb-2">Активные пользователи</h2>
                <p className="text-2xl font-bold">567</p>
                <p className="text-sm text-gray-500">Пользователи, заходившие в течение недели</p>
            </div>


            <div className="bg-white p-4 rounded-lg shadow">
                <h2 className="text-lg font-semibold mb-2">Проданных товаров</h2>
                <p className="text-2xl font-bold">{countProduct}</p>
                <p className="text-sm text-gray-500">Товары, проданные за все время</p>
            </div>


            <div className="bg-white p-4 rounded-lg shadow">
                <h2 className="text-lg font-semibold mb-2">Общий доход</h2>
                <p className="text-2xl font-bold">{priceTotal} ₽</p>
                <p className="text-sm text-gray-500">Доход за все время</p>
            </div>
        </div>

        {/*<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">*/}
        {/*    <div className="bg-white p-4 rounded-lg shadow">*/}
        {/*        <h2 className="text-lg font-semibold mb-2">Общее количество заказов</h2>*/}
        {/*        <p className="text-2xl font-bold">{countOrder}</p>*/}
        {/*        <p className="text-sm text-gray-500">Всего заказов за всё время</p>*/}
        {/*    </div>*/}


        {/*    <div className="bg-white p-4 rounded-lg shadow">*/}
        {/*        <h2 className="text-lg font-semibold mb-2">Активные пользователи</h2>*/}
        {/*        <p className="text-2xl font-bold">567</p>*/}
        {/*        <p className="text-sm text-gray-500">Пользователи, заходившие в течение недели</p>*/}
        {/*    </div>*/}


        {/*    <div className="bg-white p-4 rounded-lg shadow">*/}
        {/*        <h2 className="text-lg font-semibold mb-2">Проданных товаров</h2>*/}
        {/*        <p className="text-2xl font-bold">{countProduct}</p>*/}
        {/*        <p className="text-sm text-gray-500">Товары, проданные за все время</p>*/}
        {/*    </div>*/}


        {/*    <div className="bg-white p-4 rounded-lg shadow">*/}
        {/*        <h2 className="text-lg font-semibold mb-2">Общий доход</h2>*/}
        {/*        <p className="text-2xl font-bold">{priceTotal} ₽</p>*/}
        {/*        <p className="text-sm text-gray-500">Доход за все время</p>*/}
        {/*    </div>*/}
        {/*</div>*/}


        <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">Последние заказы</h2>
            <div className="overflow-x-auto">
                <List
                    hiddenAction={true}
                    columns={columns}
                    loading={loading}
                    handleDeleteClick={() => {
                    }}
                    handleEditClick={() => {
                    }}
                    items={orders}/>
            </div>
        </div>
    </div>
}
