export const Money = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_8524)">
            <path
                d="M5.5 9.511C5.576 10.465 6.33 11.208 7.682 11.296V12H8.282V11.291C9.682 11.193 10.5 10.445 10.5 9.359C10.5 8.372 9.874 7.863 8.755 7.599L8.282 7.487V5.57C8.882 5.638 9.264 5.966 9.356 6.42H10.408C10.332 5.501 9.544 4.782 8.282 4.704V4H7.682V4.719C6.487 4.836 5.672 5.555 5.672 6.572C5.672 7.472 6.278 8.044 7.285 8.279L7.682 8.377V10.411C7.067 10.318 6.66 9.981 6.568 9.511H5.5ZM7.677 7.345C7.087 7.208 6.767 6.929 6.767 6.509C6.767 6.039 7.112 5.687 7.682 5.584V7.344L7.677 7.345ZM8.369 8.538C9.086 8.704 9.417 8.973 9.417 9.448C9.417 9.99 9.005 10.362 8.282 10.43V8.518L8.369 8.538Z"
                fill="white"/>
            <path
                d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                fill="white"/>
            <path
                d="M8 13.5C6.54131 13.5 5.14236 12.9205 4.11091 11.8891C3.07946 10.8576 2.5 9.45869 2.5 8C2.5 6.54131 3.07946 5.14236 4.11091 4.11091C5.14236 3.07946 6.54131 2.5 8 2.5C9.45869 2.5 10.8576 3.07946 11.8891 4.11091C12.9205 5.14236 13.5 6.54131 13.5 8C13.5 9.45869 12.9205 10.8576 11.8891 11.8891C10.8576 12.9205 9.45869 13.5 8 13.5ZM8 14C8.78793 14 9.56815 13.8448 10.2961 13.5433C11.0241 13.2417 11.6855 12.7998 12.2426 12.2426C12.7998 11.6855 13.2417 11.0241 13.5433 10.2961C13.8448 9.56815 14 8.78793 14 8C14 7.21207 13.8448 6.43185 13.5433 5.7039C13.2417 4.97595 12.7998 4.31451 12.2426 3.75736C11.6855 3.20021 11.0241 2.75825 10.2961 2.45672C9.56815 2.15519 8.78793 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_2_8524">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}
