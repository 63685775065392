export const Product = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_7678)">
            <path
                d="M8.00004 1C8.53047 1 9.03918 1.21071 9.41426 1.58579C9.78933 1.96086 10 2.46957 10 3V5H6.00004V3C6.00004 2.46957 6.21075 1.96086 6.58583 1.58579C6.9609 1.21071 7.46961 1 8.00004 1ZM11 5V3C11 2.20435 10.684 1.44129 10.1214 0.87868C9.55875 0.31607 8.79569 0 8.00004 0C7.20439 0 6.44133 0.31607 5.87872 0.87868C5.31611 1.44129 5.00004 2.20435 5.00004 3V5H3.36004C3.00092 5.00008 2.65374 5.129 2.3816 5.36334C2.10946 5.59767 1.93043 5.92186 1.87704 6.277L0.850042 13.13C0.796829 13.4856 0.820941 13.8485 0.920732 14.1939C1.02052 14.5393 1.19364 14.8591 1.42829 15.1315C1.66293 15.404 1.95357 15.6226 2.28039 15.7724C2.6072 15.9223 2.9625 15.9999 3.32204 16H12.677C13.0367 16.0001 13.3921 15.9226 13.719 15.7728C14.046 15.6229 14.3367 15.4044 14.5715 15.1319C14.8062 14.8595 14.9794 14.5396 15.0793 14.1941C15.1791 13.8486 15.2033 13.4857 15.15 13.13L14.122 6.277C14.0687 5.92204 13.8898 5.59798 13.6179 5.36366C13.3459 5.12935 12.999 5.00032 12.64 5H11ZM10 6V7.5C10 7.63261 10.0527 7.75979 10.1465 7.85355C10.2403 7.94732 10.3674 8 10.5 8C10.6327 8 10.7598 7.94732 10.8536 7.85355C10.9474 7.75979 11 7.63261 11 7.5V6H12.639C12.7587 6.00012 12.8744 6.04318 12.9651 6.12135C13.0557 6.19952 13.1153 6.30762 13.133 6.426L14.161 13.277C14.1931 13.4904 14.1788 13.7082 14.119 13.9156C14.0592 14.1229 13.9554 14.315 13.8146 14.4785C13.6738 14.6421 13.4994 14.7733 13.3032 14.8633C13.1071 14.9533 12.8938 15 12.678 15H3.32204C3.10624 15 2.89298 14.9533 2.69684 14.8633C2.50069 14.7733 2.32627 14.6421 2.18549 14.4785C2.04471 14.315 1.94088 14.1229 1.88109 13.9156C1.8213 13.7082 1.80696 13.4904 1.83904 13.277L2.86704 6.426C2.88473 6.30779 2.94418 6.19982 3.03463 6.12167C3.12507 6.04352 3.24051 6.00036 3.36004 6H5.00004V7.5C5.00004 7.63261 5.05272 7.75979 5.14649 7.85355C5.24026 7.94732 5.36743 8 5.50004 8C5.63265 8 5.75983 7.94732 5.85359 7.85355C5.94736 7.75979 6.00004 7.63261 6.00004 7.5V6H10Z"
                fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_2_7678">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}
