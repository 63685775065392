import {ReviewsService} from "../service/reviewsService";
import '../styles/button.scss'
const Review = ({ review }) => {
    const { id, name, rating, text, created_at, product, order } = review;

    const reviewsService = new ReviewsService()

    const deleteHandler = async () => {
        await reviewsService.delete(id)
    }


    // Создаем массив звезд
    const stars = Array.from({ length: 5 }, (_, index) => (
        <svg
            key={index}
            style={{color: index < rating ? '#EAB308' : '#D1D5DB'}}
            className={`h-5 w-5`}
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
        >
            <path d="M9.049 2.927a1 1 0 011.902 0l1.336 4.083h4.29a1 1 0 01.59 1.81l-3.462 2.522 1.336 4.084a1 1 0 01-1.541 1.11L10 13.348l-3.462 2.522a1 1 0 01-1.541-1.11l1.336-4.083-3.462-2.523a1 1 0 01.59-1.81h4.29L9.049 2.927z" />
        </svg>
    ));

    return (
        <div className="bg-white shadow-md rounded-lg p-6 mb-4 border-[1px]">
            <div className="flex items-center justify-between">
                <h4 className="text-lg font-semibold">{name}</h4>
                <div className="flex items-center">{stars}</div>
            </div>
            <p className="text-gray-600 mt-2">{text}</p>
            <div className="text-sm text-gray-500 mt-4">
                <p>Дата: {new Date(created_at).toLocaleDateString()}</p>
                <p>Дата
                    заказа: {new Date(order.created_at).toLocaleDateString() + " " + new Date(order.created_at).toLocaleTimeString()}</p>
                <br/>
                <p><b>Название продукта:</b> {product.title}</p>

                <div className="flex flex-row">
                    <div className="mr-[20px]">
                        <b>Вставка</b>: {order.items.map(item =>
                        <p>{product.id === item.product_id && item.decorate.title}</p>)}
                    </div>
                    <div className="mr-[20px]">
                        <b>Проба</b>: {order.items.map(item =>
                        <p>{product.id === item.product_id && item.proba.title}</p>)}
                    </div>
                    <div>
                        <b>Размер</b>: {order.items.map(item =>
                        <p>{product.id === item.product_id && item.size.title}</p>)}
                    </div>
                </div>
                <button className='del' onClick={() => deleteHandler()}>Удалить</button>
            </div>
        </div>
    );
};

export default Review;
